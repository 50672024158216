import './home.scss';
import React, { useEffect, useState } from 'react';
import { Backdrop, Grid } from '@mui/material';

import {
  getAllNotStartedSessionsByPharmacy,
  getAllSessionsByPharmacy,
  partialUpdateEntity,
  reset,
} from 'app/entities/session/session.reducer';
import { getServiceEntities } from 'app/entities/service/service.reducer';
import { TableCommponent } from '../pharmacist-work-flow/listing';
import { DashboardMenuCommp } from '../PharmacistSideMenu/dashboardMenuCommp';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { logout } from 'app/shared/reducers/authentication';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
import moment from 'moment';
import PharmacistStatsTable from 'app/modules/pharmacist-work-flow/pharmacist-stats/pharmacist-stats';

export const Home = props => {
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const role =
    localStorage.getItem('role') === null || localStorage.getItem('role') === '' || localStorage.getItem('role') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('role'));
  const dispatch = useAppDispatch();
  const [sessionStatusValueSideBar, setSessionStatusValueSideBar] = useState('');
  const isAdmin =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN])) && role === 'ROLE_ADMIN';
  const isClient =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CLIENT])) && role === 'CLIENT';
  const isPharmacist =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PHARMACIST])) && role === 'PHARMACIST';
  const [selectedColor, setSelectedColor] = useState(null); // State for selected color

  const {
    sessionByPharmacyEntities,
    notStartedSessionByPharmacyEntities,
    sessionCounts,
    session_status,
    totalCount,
    session_type,
    rows,
    pageNo,
    loading,
  } = useAppSelector(state => state.session);
  const pageNum = pageNo;
  const rowNumber = rows;
  const { serviceEntities } = useAppSelector(state => state.service);

  // useEffect(() => {
  //   const data = {
  //     service_id: 1,
  //     session_status: '1',
  //     session_type: localStorage.getItem('pharmacist_type') === '"follow_up"' ? '2' : '1',
  //     pageNo: pageNum,
  //     rows: rowNumber,
  //     q: '',
  //     sort: {
  //       address: false,
  //       fullName: false,
  //       nextSession: false,
  //     },
  //   };
  //   dispatch(getServiceEntities({}));
  //   dispatch(getAllSessionsByPharmacy(data));
  //   dispatch(getAllNotStartedSessionsByPharmacy());
  // }, []);

  const handleSessionStatusValue = status => {
    console.log({ status, session_status });
    if (status === null) {
      setSessionStatusValueSideBar(session_status);
    } else {
      setSessionStatusValueSideBar(status);
    }
  };

  useEffect(() => {
    const savedColor = localStorage.getItem('prevSelectedColor');
    if (savedColor) {
      setSelectedColor(savedColor); // Set color if it exists in localStorage
    }
  }, []); // This effect runs only once after the initial render

  const handleColorChange = color => {
    localStorage.setItem('prevSelectedColor', color); // Save selected color to local storage
    setSelectedColor(color); // Update selected color state
  };

  useEffect(() => {
    const checkAnimationCondition =
      notStartedSessionByPharmacyEntities.length > 0 &&
      notStartedSessionByPharmacyEntities?.some(item => {
        const isIntervention = item?.prevSessionType?.id === 1;
        const isNotStarted = item?.sessionStatus?.id === 10;
        const callBackTimeReached = moment().isSameOrAfter(item?.callBackDate);

        return isIntervention && isNotStarted && callBackTimeReached;
      });
    console.log({ notStartedSessionByPharmacyEntities });
    setIsAnimationActive(checkAnimationCondition);
  }, [notStartedSessionByPharmacyEntities]);

  return (
    <>
      <Grid lg={12} xs={12} sm={12} container>
        <DashboardMenuCommp
          sessionCounts={sessionCounts}
          sessionByPharmacyEntities={sessionByPharmacyEntities}
          getAllSessionsByPharmacy={getAllSessionsByPharmacy}
          partialUpdateEntity={partialUpdateEntity}
          reset={reset}
          pageNumber={pageNo}
          isAdmin={isAdmin}
          isClient={isClient}
          isPharmacist={isPharmacist}
          rowsPer={rows}
          totalCount={totalCount}
          sessionTypeValue={session_type}
          sessionStatusValue={session_status}
          handleSessionStatusValue={handleSessionStatusValue}
          selectedColor={selectedColor} // Pass selectedColor as a prop
          onColorChange={handleColorChange} // Pass color change handler
          isAnimationActive={isAnimationActive}
        />
        {selectedColor === 'nine' ? (
          <PharmacistStatsTable />
        ) : (
          <TableCommponent
            sessionByPharmacyEntities={sessionByPharmacyEntities}
            getAllSessionsByPharmacy={getAllSessionsByPharmacy}
            reset={reset}
            pageNumber={pageNo}
            loading={loading}
            rowsPer={rows}
            totalCount={totalCount}
            sessionTypeValue={session_type}
            sessionStatusValue={session_status}
            selectedColor={selectedColor} // Pass selectedColor as a prop
          />
        )}
      </Grid>
      <Backdrop open={loading === true}>
        <div className="spinner"></div>
      </Backdrop>
    </>
  );
};

export default Home;
