import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCallback, useEffect, useState } from 'react';
import { pdf, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { getDownloadPharmacistStats, getPharmacistStats, getPharmacistStatsByPharmacy } from 'app/modules/admin-work-flow/admin-reducer';
import { IPharmacistStatsParam } from 'app/modules/admin-work-flow/interface';
import { isValidDate } from 'app/shared/util/utits';
import PDFDocument from 'app/modules/pharmacist-work-flow/pharmacist-stats/pharmacistStatsPDF';

const intialState: IPharmacistStatsParam = {
  size: 50,
  page: 0,
  pharmacyName: '',
  from: '',
  to: '',
  pharmacist: '',
};

const pharmacistStatsHook = () => {
  const { showMenu } = useDashboardContext();
  const dispatch = useAppDispatch();
  const pharmacistStats = useAppSelector(state => state.admin.pharmacistWorkflowStats);
  const pharmacistDetail = useAppSelector(state => state.authentication.account);
  const pharmacistId = localStorage.getItem('id');
  const pharmacistName = localStorage.getItem('firstName');
  const [searchCreteria, setsearchCreteria] = useState<IPharmacistStatsParam>(intialState);
  const [totalIntervention, setTotalIntervention] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalFollowUp, setTotalFollowUp] = useState(0);

  const emptyRowCount = 13;
  const emptyCellCount = 3;
  const getServicePharmacistStats = useCallback(async () => {
    if (pharmacistId) {
      const params = {
        pharmacist: pharmacistId,
      };
      setsearchCreteria({
        pharmacist: pharmacistId,
      });
      const response = await dispatch(getPharmacistStatsByPharmacy(params));
      const total = response['payload']['data']['totals'];
      setTotalIntervention(total.totalIntervention);
      setTotalEarning(total.totalEarnings);
      setTotalFollowUp(total.totalFollowUp);
    }
  }, [pharmacistId]);
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      pharmacyName: value,
    };

    dispatch(getPharmacistStatsByPharmacy(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    console.log({ value });
    setsearchCreteria({
      ...searchCreteria,
      pharmacyName: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getPharmacistStatsByPharmacy(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getPharmacistStatsByPharmacy(params));
  };

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(getPharmacistStatsByPharmacy(params));
    if (response?.type === 'app-users/pharmacist_stats_per_pharmacy/fulfilled') {
      const allPharmacies = response?.payload?.data.pharmacies;
      const total = response['payload']['data']['totals'];
      const totalCompletedInterventions = total.totalIntervention;
      const totalEarnings = total.totalEarnings;
      const totalCompletedFollowUps = total.totalFollowUp;
      const allTotals = {
        totalIntervention: totalCompletedInterventions,
        totalEarnings,
        totalFollowUp: totalCompletedFollowUps,
      };
      // await generatePDF(allPharmacies, allTotals);
    }
  }, [searchCreteria]);

  const handleRefresh = useCallback(() => {
    const params = {
      ...searchCreteria,
      pharmacist: pharmacistId,
    };

    setsearchCreteria({
      ...searchCreteria,
      pharmacist: pharmacistId,
      from: '',
      to: '',
      pharmacyName: '',
    });

    dispatch(getPharmacistStatsByPharmacy(params));
  }, []);

  useEffect(() => {
    getServicePharmacistStats();
  }, []);
  const states = {
    dispatch,
    pharmacistStats,
    pharmacistId,
    emptyRowCount,
    emptyCellCount,
    showMenu,
    totalIntervention,
    totalEarning,
    totalFollowUp,
    searchCreteria,
    pharmacistName,
    pharmacistDetail,
  };

  const handlers = {
    handleSearchChange,
    handledateFrom,
    handledateTill,
    handleRefresh,
    getDownloadData,
  };
  return {
    states,
    handlers,
  };
};
export default pharmacistStatsHook;
