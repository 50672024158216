import { useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { callBack, getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { useNavigate } from 'react-router-dom';
interface ISessionListParams {
  sort: any;
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
  dateFrom?: any;
  dateTill?: any;
}
const pharmacistDetailPageHook = () => {
  const [openCallback, setOpenCallback] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [patientId, setPatientId] = useState(null);
  const [callBackValues, setCallBackValues] = useState({
    date: '',
    time: '',
    comment: '',
  });

  const [errorsCallBack, setErrorsCallBack] = useState({ date: '', time: '', comment: '' });

  const handleClickOpenCallBack = (id = null) => {
    if (id) {
      setPatientId(id);
    }
    setOpenCallback(true);
  };

  const handleCloseCallBack = () => {
    setOpenCallback(false);
    setCallBackValues({ date: '', time: '', comment: '' });
    setErrorsCallBack({ date: '', time: '', comment: '' });
  };

  const setValuesCallBack = (field, value) => {
    setCallBackValues(prev => ({ ...prev, [field]: value }));
  };

  const validate = () => {
    const newErrors = { date: '', time: '', comment: '' };
    let isValid = true;

    if (!callBackValues.date) {
      newErrors.date = 'Date is required';
      isValid = false;
    }
    if (!callBackValues.time) {
      newErrors.time = 'Time is required';
      isValid = false;
    }
    if (!callBackValues.comment.trim()) {
      newErrors.comment = 'Comment is required';
      isValid = false;
    }

    setErrorsCallBack(newErrors);
    return isValid;
  };

  const handleSubmitCallBack = async (id: number) => {
    if (validate() && id) {
      const params = {
        ...callBackValues,
        id,
      };
      const res = await dispatch(callBack(params));
      console.log('Submitted Values:', res);
      if (res.type === 'session/add-call-back/:id/fulfilled' && res.payload['status'] === 201) {
        setOpenCallback(false);
        navigate('/');
        const data = {
          service_id: 1,
          session_status: '1',
          session_type: localStorage.getItem('pharmacist_type') === '"follow_up"' ? '2' : '1',
          pageNo: '',
          rows: '',
          q: '',
        };
        dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
      }
    }
  };

  const states = {
    openCallback,
    errorsCallBack,
    callBackValues,
    patientId,
  };

  const handlers = {
    handleClickOpenCallBack,
    handleCloseCallBack,
    handleSubmitCallBack,
    setValuesCallBack,
  };
  return {
    states,
    handlers,
  };
};
export default pharmacistDetailPageHook;
