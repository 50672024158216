import React from 'react';
import { useParams, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import PharmacyModal from 'app/modules/login/pharmacy-modal';
import LocumModal from 'app/modules/login/locum-modal';
import ConsentComponent from 'app/modules/ConsentScreen/consentCommp';
import { CXFormComp } from './modules/pharmacist-work-flow/session-medicine-review';
import FileUploadComp from 'app/modules/deo-work-flow/session-deo-file-uploaded';
import { FileDashBoard } from 'app/modules/deo-work-flow/session-deo-listing';
import BlankSheetFormComp from 'app/modules/deo-work-flow/session-deo-blanksheet-form-comp';
import AdminDashboard from './modules/admin-work-flow/admin-dashboard';
import SetPasswordModal from './modules/set-password/set-password';
import ContractorMenu from './modules/contractor-workflow/contractorMenu';
import ForgotPasswordModal from './modules/set-password/forgot-password';
import Page from 'app/modules/deo-work-flow/deo-listing-component/page-content';
import EditPharmacy from './modules/deo-work-flow/deo-listing-component/editPharmacy';
import RoleModal from 'app/modules/login/role-modal';
import DashboardMenuCommp from 'app/modules/deo-work-flow/deo-side-menu/dashboard-menu-commp';
import InstructionModal from 'app/modules/login/instruction-modal';
import OtpModal from './modules/login/otp-modal';
import ManagePharmacistDashboard from 'app/modules/admin-work-flow/manage-pharmacist/manage-pharmacist-dashboard';
import CXFormCompAdmin from './modules/admin-work-flow/session-medicine-review';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  const { id } = useParams();

  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route
          index
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/login/otp" element={<OtpModal />} />
        <Route path="login" element={<Login />} />
        <Route path="setpassword" element={<SetPasswordModal />} />
        <Route path="login/forgotPassword" element={<ForgotPasswordModal />} />
        <Route
          path="/login/pharmacy"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <PharmacyModal />
            </PrivateRoute>
          }
        />
        <Route
          path="/login/gphc"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <LocumModal />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path="/login/instruction"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <InstructionModal />
            </PrivateRoute>
          }
        />
        <Route path="/role" element={<RoleModal />} />
        {/* <Route path="/login/gphc" element={<LocumModal />} /> */}
        <Route
          path="/operator/dashboard"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              {/*<div style={{ display: 'flex' }}>*/}
              {/*  <Page id={id} />*/}
              {/*</div>*/}
              <DashboardMenuCommp />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/dashboard/edit/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.MANAGER_DEO]}>
              <EditPharmacy />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/dashboard/session-deo-file-uploaded"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              <div style={{ display: 'flex' }}>
                <FileUploadComp />
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-pharmacist/dashboard"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MANAGE_PHARMACIST]}>
              <ManagePharmacistDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/client/dashboard"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.CLIENT]}>
              <ContractorMenu />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/create-patient"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              <BlankSheetFormComp />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/edit-patient/:id"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO, AUTHORITIES.ADMIN, AUTHORITIES.CLIENT]}
            >
              <BlankSheetFormComp />
            </PrivateRoute>
          }
        />
        <Route
          path="/pharmacist/edit-completed/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <BlankSheetFormComp />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/edit-patient/:id/:file_id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              <BlankSheetFormComp />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/session-listing"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              <FileDashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="/operator/edit/file/:file_id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEO, AUTHORITIES.MANAGER_DEO, AUTHORITIES.LEAD_DEO]}>
              <FileDashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient/consent/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST]}>
              <ConsentComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient/session/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PHARMACIST, AUTHORITIES.ADMIN]}>
              <CXFormComp />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/session/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <CXFormCompAdmin />
            </PrivateRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/admin/add-role"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <AdminAddNewRole />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
