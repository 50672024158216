import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

import pharmacistStatsHook from 'app/modules/pharmacist-work-flow/hooks/pharmacistStatsHook';
import { generalMonthArray } from 'app/shared/util/utits';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { pdf, Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { getPharmacistStatsByPharmacy } from 'app/modules/admin-work-flow/admin-reducer';
import { saveAs } from 'file-saver';
import moment from 'moment';

interface PDFDocumentProps {
  pharmacies: any[];
  totals: any;
}
export const PharmacistStatsTable = props => {
  const { states, handlers } = pharmacistStatsHook();
  const {
    dispatch,
    pharmacistStats,
    pharmacistId,
    emptyRowCount,
    emptyCellCount,
    showMenu,
    totalIntervention,
    totalEarning,
    totalFollowUp,
    searchCreteria,
    pharmacistName,
    pharmacistDetail,
  } = states;
  const {
    handleSearchChange,
    handledateFrom,
    handledateTill,
    handleRefresh,
    // getDownloadData
  } = handlers;
  const styles = StyleSheet.create({
    page: { padding: 30 },
    header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 },
    logo: { width: 350, height: 100 },
    title: { fontSize: 30, marginBottom: 30, color: '#024db1', textAlign: 'center' },

    invoiceContainer: { alignItems: 'flex-start' },
    invoiceHeading: { fontSize: 12, fontWeight: 'bold', color: 'black' },
    invoiceNumber: { fontSize: 12, color: 'black', marginTop: 2 },

    pharmacistContainer: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 },
    column: { flex: 1 },

    rowContainer: { flexDirection: 'row', alignItems: 'center', marginBottom: 5 }, // Adjusted alignment
    greenHeading: { fontSize: 12, fontWeight: 'bold', color: 'green', marginRight: 5 }, // Added marginRight
    blackText: { fontSize: 12, color: 'black' },

    dateContainer: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
    dateText: { fontSize: 12, color: 'black' },

    row: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#ddd', padding: 5 },
    bold: { fontWeight: 'bold' },
    cell: { flex: 1, fontSize: 12, textAlign: 'left' },
    totalRow: { backgroundColor: '#f2f2f2', paddingVertical: 5 },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20,
    },
  });

  const PDFDocument: React.FC<PDFDocumentProps> = ({ pharmacies, totals }) => (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={styles.headerContainer}>
          <Image src={'../../../../content/assets/pharmacy-solution-logo.png'} style={styles.logo} />
          {/*<Text style={styles.title}>Pharmacy Solutions</Text>*/}
        </View>

        {/* Header Section with INV-Number */}
        <View style={styles.pharmacistContainer}>
          <View style={styles.column}>
            <View style={styles.rowContainer}>
              <Text style={styles.invoiceHeading}>INV-Number:</Text>
              <Text style={styles.blackText}>{Math.floor(100000 + Math.random() * 900000).toString()}</Text>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.rowContainer}></View>
          </View>
          <View style={styles.column}>
            <View style={styles.rowContainer}>
              <Text style={[styles.invoiceHeading]}>Generated Date:</Text>
              <Text style={styles.blackText}>{moment().format('DD-MM-YYYY')}</Text>
            </View>
          </View>
        </View>

        {/* Pharmacist Details */}
        <View style={styles.pharmacistContainer}>
          <View style={styles.column}>
            <View style={styles.rowContainer}>
              <Text style={styles.greenHeading}>Pharmacist Name:</Text>
              <Text style={styles.blackText}>{pharmacistDetail?.firstName}</Text>
            </View>
            <View style={styles.rowContainer}>
              <Text style={styles.greenHeading}>Pharmacist Email:</Text>
              <Text style={styles.blackText}>{pharmacistDetail?.email}</Text>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.rowContainer}></View>
            <View style={styles.rowContainer}></View>
          </View>
          <View style={styles.column}>
            <View style={styles.rowContainer}>
              <Text style={styles.greenHeading}>Pharmacist GPHC:</Text>
              <Text style={styles.blackText}>{pharmacistDetail?.gphcNumber}</Text>
            </View>
            <View style={styles.rowContainer}>
              <Text style={styles.greenHeading}>Pharmacist ID:</Text>
              <Text style={styles.blackText}>{'PH-' + pharmacistDetail?.id}</Text>
            </View>
          </View>
        </View>

        {/* Date Section */}
        <View style={styles.pharmacistContainer}>
          <View style={styles.column}>
            <View style={styles.rowContainer}>
              <Text style={[styles.dateText, styles.bold]}>From: </Text>
              <Text style={styles.blackText}>{searchCreteria?.from ? moment(searchCreteria?.from).format('DD-MM-YYYY') : '-'}</Text>
            </View>
            <View style={styles.rowContainer}>
              <Text style={[styles.dateText, styles.bold]}>To: </Text>
              <Text style={styles.blackText}>{searchCreteria?.to ? moment(searchCreteria?.to).format('DD-MM-YYYY') : '-'}</Text>
            </View>
          </View>
        </View>

        {/* Table Headers */}
        <View style={[styles.row, styles.bold]}>
          <Text style={[styles.cell, styles.bold]}>Pharmacy Name</Text>
          <Text style={[styles.cell, styles.bold]}>Completed Interventions</Text>
          <Text style={[styles.cell, styles.bold]}>Earnings (£)</Text>
          <Text style={[styles.cell, styles.bold]}>Completed Follow-ups</Text>
        </View>

        {/* Pharmacy Data */}
        {pharmacies.map(pharmacy => (
          <View key={pharmacy.pharmacyId} style={styles.row}>
            <Text style={[styles.cell, { color: 'black' }]}>{pharmacy.pharmacyName}</Text>
            <Text style={styles.cell}>{pharmacy.completedIntervention}</Text>
            <Text style={styles.cell}>£ {pharmacy.earnings}</Text>
            <Text style={styles.cell}>{pharmacy.completedFollowUp}</Text>
          </View>
        ))}

        {/* Totals Row */}
        <View style={[styles.row, styles.totalRow]}>
          <Text style={[styles.cell, styles.bold]}>Total</Text>
          <Text style={[styles.cell, styles.bold]}>{totals.totalIntervention}</Text>
          <Text style={[styles.cell, styles.bold]}>£ {totals.totalEarnings}</Text>
          <Text style={[styles.cell, styles.bold]}>{totals.totalFollowUp}</Text>
        </View>
      </Page>
    </Document>
  );

  const generatePDF = async (pharmacies: any[], totals: any) => {
    console.log({ pharmacistDetail });
    const docElement = <PDFDocument pharmacies={pharmacies} totals={totals} />; // ✅ Use as JSX

    // Convert to blob
    const blob = await pdf(docElement).toBlob();

    // Save the file
    saveAs(blob, 'PharmacyStats.pdf');
  };
  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(getPharmacistStatsByPharmacy(params));
    if (response?.type === 'app-users/pharmacist_stats_per_pharmacy/fulfilled') {
      const allPharmacies = response?.payload?.data.pharmacies;
      const total = response['payload']['data']['totals'];
      const totalCompletedInterventions = total.totalIntervention;
      const totalEarnings = total.totalEarnings;
      const totalCompletedFollowUps = total.totalFollowUp;
      const allTotals = {
        totalIntervention: totalCompletedInterventions,
        totalEarnings,
        totalFollowUp: totalCompletedFollowUps,
      };
      await generatePDF(allPharmacies, allTotals);
    }
    console.log({ response });
  }, [searchCreteria]);
  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      item
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: 35,
        position: 'fixed',
        right: '2%',
        maxWidth: '-webkit-fill-available',
        marginLeft: showMenu ? '6%' : '17%',
        transition: 'ease 0.5s',
      }}
    >
      <Grid container item xs={12} gap={1} sx={{ justifyContent: 'start', alignItems: 'center' }}>
        <Grid item xs={2}>
          <Tooltip title="Date From">
            <div style={{ display: 'flex' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  value={searchCreteria.from === '' ? null : searchCreteria.from}
                  // className="clock"
                  format="DD/MM/YYYY"
                  onChange={newValue => handledateFrom(newValue)}
                  className={'mui-field-design-filter'}
                />
              </LocalizationProvider>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Date Till">
            <div style={{ display: 'flex' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  value={searchCreteria.to === '' ? null : searchCreteria.to}
                  className="clock mui-field-design-filter"
                  format="DD/MM/YYYY"
                  onChange={newValue => handledateTill(newValue)}
                />
              </LocalizationProvider>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={1.7}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Total Completed Interventions: {totalIntervention}</Typography>
        </Grid>
        <Grid item xs={1.2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Total Earnings: £ {totalEarning}</Typography>
        </Grid>
        <Grid item xs={1.7}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Total Completed Follow-ups: {totalFollowUp}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right', marginLeft: '8%' }}>
          <Tooltip title="Refresh" sx={{ marginRight: '2px' }}>
            <IconButton
              onClick={handleRefresh}
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              onClick={() => getDownloadData()}
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '30px' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid lg={12} container style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <Grid lg={12} item style={{ height: 30, marginTop: 5, marginBottom: '26px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '135ch' }}>
              <OutlinedInput
                style={{ borderRadius: 'none', height: 45, width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 }, maxLength: 50 }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>
        <Paper style={{ width: '100%', border: 'none' }}>
          <TableContainer style={{ maxHeight: '75vh', border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead sx={{ height: 30, border: 'none' }}>
                <TableRow
                  sx={{
                    height: 30,
                    border: 'none',
                    borderTop: '0.1px solid #dadce1',
                    borderBottom: '0.1px solid #dadce1',
                  }}
                >
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <strong>Pharmacy Name</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <strong>Completed Interventions</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <strong>Earnings</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <strong>Completed Follow-ups</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pharmacistStats?.pharmacies?.map((pharmacy, index) => (
                  <TableRow key={index}>
                    <TableCell>{pharmacy.pharmacyName}</TableCell>
                    <TableCell>{parseFloat(pharmacy.completedIntervention || 0)}</TableCell>
                    <TableCell>£ {parseFloat(pharmacy.earnings || 0)}</TableCell>
                    <TableCell>{parseFloat(pharmacy.completedFollowUp || 0)}</TableCell>
                  </TableRow>
                ))}
                {/*<TableRow>*/}
                {/*  <TableCell>*/}
                {/*    <strong>Total</strong>*/}
                {/*  </TableCell>*/}
                {/*  <TableCell >*/}
                {/*    <strong>{totalIntervention.toFixed(1)}</strong>*/}
                {/*  </TableCell>*/}
                {/*  <TableCell>*/}
                {/*    <strong>£ {totalEarning.toFixed(1)}</strong>*/}
                {/*  </TableCell>*/}
                {/*  <TableCell>*/}
                {/*    <strong>{totalFollowUp.toFixed(1)}</strong>*/}
                {/*  </TableCell>*/}
                {/*</TableRow>*/}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination*/}
          {/*  rowsPerPageOptions={[50, 150, 300]}*/}
          {/*  component="div"*/}
          {/*  // count={totalCount}*/}
          {/*  // rowsPerPage={rowsPerPage}*/}
          {/*  // page={page}*/}
          {/*  // onPageChange={handleChangePage}*/}
          {/*  // onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*  labelRowsPerPage={*/}
          {/*    <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>*/}
          {/*  }*/}
          {/*  labelDisplayedRows={({ from, to, count }) => (*/}
          {/*    <div style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>*/}
          {/*      {`${from}        -      ${to}        of       ${count}`}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*/>*/}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default PharmacistStatsTable;
