import React, { useCallback, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  TablePagination,
  TextField,
  Tooltip,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  callAttempt,
  deleteEntity,
  followUpNotContactableToComplete,
  getAllSessionsByPharmacy,
  getDeoEntities,
  partialUpdateEntity,
} from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { NMS_STATUS, NMS_TYPES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { getAllServicePharmacists, getAllSessionsListingWithDateRange } from 'app/modules/admin-work-flow/admin-reducer';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isValidDate } from 'app/shared/util/utits';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { DoDisturb, Done } from '@mui/icons-material';
import pharmacistDetailPageHook from 'app/modules/pharmacist-work-flow/hooks/pharmacistDetailPageHook';

interface ISessionListParams {
  sort: any;
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
  dateFrom?: any;
  dateTill?: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  rows: any;
  sort: any;
}
interface ISessionCallAttempt {
  id: any;
  callAttempt: any;
  session_status: any;
  session_type: any;
  pageNo: null;
  rows: null;
  sort: any;
}
export const TableCommponent = props => {
  const { states, handlers } = pharmacistDetailPageHook();
  const { handleClickOpenCallBack, handleCloseCallBack, handleSubmitCallBack, setValuesCallBack } = handlers;
  const { openCallback, errorsCallBack, callBackValues, patientId } = states;
  const {
    sessionByPharmacyEntities,
    reset,
    sessionCounts,
    pageNumber,
    totalCount,
    rowsPer,
    sessionTypeValue,
    sessionStatusValue,
    loading,
    selectedColor,
  } = props;
  const pageNo = useAppSelector(state => state.session.pageNo);
  const rowsPerPageNo = useAppSelector(state => state.session.rows);
  const [page, setPage] = React.useState(pageNo);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageNo);
  const [sessionToHandleConsent, setSessionToHandleConsent] = useState(null);
  const currentAppUser = useAppSelector(state => state.authentication.account);
  const [concent, setConcent] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [recordId, setRecordId] = useState('');
  const [isDialogOpenUndo, setIsDialogOpenUndo] = useState(false);
  const [isDialogOpenDecline, setIsDialogOpenDecline] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTill, setDateTill] = useState(null);

  const rule =
    localStorage.getItem('pharmacy_rule') === null ||
    localStorage.getItem('pharmacy_rule') === '' ||
    localStorage.getItem('pharmacy_rule') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('pharmacy_rule'));
  const emptyRowCount = 13;
  const emptyCellCount = 10;
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [selectedItems, setSelectedItems] = useState({
    address: false,
    fullName: false,
    nextSession: false,
  });
  const tableContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const savedPosition = localStorage.getItem('tableScrollPosition');
    if (savedPosition) {
      tableContainerRef.current.scrollTop = parseInt(savedPosition, 10);
    }
  }, []);

  const handleScroll = () => {
    if (tableContainerRef.current) {
      const position = tableContainerRef.current.scrollTop;
      localStorage.setItem('tableScrollPosition', position);
    }
  };

  useEffect(() => {
    setPage(pageNo);
    setRowsPerPage(rowsPerPageNo);
  }, [pageNo || rowsPerPageNo]);

  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  const handleClickSort = event => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    setSelectedItems(prevState => ({
      ...prevState,
      [name]: checked,
    }));

    const sortBy = {
      ...selectedItems, // previous state
      [name]: checked, // current change
    };
    const data = {
      sort: sortBy,
      service_id: 1,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: rowsPerPage,
      q: '',
    };

    const result = dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      sort: selectedItems,
      service_id: 1,
      session_status: sessionTypeValue,
      session_type: sessionStatusValue,
      pageNo: newPage,
      rows: rowsPerPage,
      q: '',
    };
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const handleRefresh = useCallback(() => {
    console.log({
      check: localStorage.getItem('pharmacist_type') === '"follow_up"',
      output: localStorage.getItem('pharmacist_type') === '"follow_up"' ? '2' : '1',
    });
    const data = {
      service_id: 1,
      session_status: '1',
      session_type: localStorage.getItem('pharmacist_type') === '"follow_up"' ? '2' : '1',
      pageNo: '',
      rows: '',
      q: '',
    };
    setSelectedItems({
      address: false,
      fullName: false,
      nextSession: false,
    });
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  }, []);

  const formatDate = date => {
    if (date) {
      // Check if the saved date is in 'YYYY-MM-DD' format
      const parsedDate = moment(date, 'YYYY-MM-DD', true);
      if (parsedDate.isValid()) {
        // Format to 'DD-MM-YYYY' to match the pattern you're showing
        return parsedDate.format('DD-MM-YYYY');
      }
      // If the date format isn't recognized, fall back to the original format
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment().format('DD-MM-YYYY');
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    const data = {
      sort: selectedItems,
      service_id: 1,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: event.target.value,
      q: '',
    };
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const handleSearch = e => {
    const data = {
      sort: selectedItems,
      service_id: 1,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: 0,
      rows: 20,
      q: e,
    };
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const debouncedSearch = debounce(handleSearch, 3000);

  const handleSearchChange = event => {
    const { value } = event.target;
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handleSessionConsent = sessionId => {
    const data = {
      id: sessionId,
      consent: true,
      sessionStatus: NMS_STATUS.IN_PROGRESS,
      sessionType: NMS_TYPES.INTERVENTION,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: rowsPerPage,
    };
    const result = dispatch(partialUpdateEntity(data as ISessionConsent));

    if (result.arg.consent) {
      navigate(`/patient/session/${sessionId}`);
    }
  };

  const letsStart = sessionId => {
    navigate(`/patient/session/${sessionId}`);
  };

  const handleActions = (sessionId, sessionStatus, attempts = 0) => {
    const data = {
      id: sessionId,
      consent: false,
      sessionStatus,
      sessionType: null,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: rowsPerPage,
    };
    if (sessionStatus === 4 && sessionToHandleConsent === null) {
      setIsDialogOpenDecline(true);
      setSessionToHandleConsent(sessionId);
    } else if (sessionStatus === 4 && sessionToHandleConsent !== null) {
      if (sessionStatus === 4 && sessionToHandleConsent === null) {
        setIsDialogOpenDecline(true);
        setSessionToHandleConsent(sessionId);
      } else if (sessionStatus === 4 && sessionToHandleConsent !== null) {
        const result = dispatch(partialUpdateEntity(data as ISessionConsent));
      } else {
        const result = dispatch(partialUpdateEntity(data as ISessionConsent));
      }
    } else {
      const result = dispatch(partialUpdateEntity(data as ISessionConsent));
    }
    if (sessionStatus === NMS_STATUS.NO_REACHABLE) {
      const callCount = Number(attempts) + Number(1);
      const params = {
        id: sessionId,
        callAttempt: callCount,
        session_status: sessionStatusValue,
        session_type: sessionTypeValue,
        pageNo: page,
        rows: rowsPerPage,
      };
      dispatch(callAttempt(params as ISessionCallAttempt));
    }
  };
  const followUpNotContactableToCompleteAction = (sessionId, sessionStatus) => {
    const data = {
      id: sessionId,
      consent: false,
      sessionStatus,
      sessionType: null,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: rowsPerPage,
    };

    const result = dispatch(followUpNotContactableToComplete(data as ISessionConsent));
  };

  const handleCallAttempt = (sessionId, attempts) => {
    const callCount = Number(attempts) + Number(1);
    setRecordId(sessionId);
    // if (callCount === 3) {
    //   setOpen(true);
    // } else {
    const data = {
      id: sessionId,
      callAttempt: callCount,
      session_status: sessionStatusValue,
      session_type: sessionTypeValue,
      pageNo: page,
      rows: rowsPerPage,
    };
    const result = dispatch(callAttempt(data as ISessionCallAttempt));
    // }
  };

  const handleDelete = () => {
    if (recordId !== '') {
      dispatch(deleteEntity(recordId))
        .then(result => {
          const data = {
            service_id: 1,
            session_status: sessionStatusValue,
            session_type: sessionTypeValue,
            pageNo: page,
            rows: rowsPerPage,
            q: '',
            sort: selectedItems,
          };
          dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
          handleClose();
        })
        .catch(error => {});
    }
  };

  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };

  const handledateFrom = e => {
    const dateFrom = isValidDate(e);
    setDateFrom(dateFrom);
    const data = {
      sort: selectedItems,
      service_id: 1,
      session_status: NMS_STATUS.COMPLETED,
      session_type: sessionTypeValue,
      pageNo: 0,
      rows: 50,
      q: '',
      dateFrom: dateFrom,
      dateTill: dateTill,
    };
    console.log(data);
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const handledateTill = e => {
    const dateTill = isValidDate(e);
    setDateTill(dateTill);
    const data = {
      sort: selectedItems,
      service_id: 1,
      session_status: NMS_STATUS.COMPLETED,
      session_type: sessionTypeValue,
      pageNo: 0,
      rows: 50,
      q: '',
      dateFrom: dateFrom,
      dateTill: dateTill,
    };
    dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
  };

  const handleEdit = id => {
    navigate(`/pharmacist/edit-completed/${id}`);
  };

  const handleOpenConsentDialogUndo = id => {
    setSessionToHandleConsent(id);
    setIsDialogOpenUndo(true); // Open the dialog
  };

  const handleCloseConsentDialogUndo = () => {
    setSessionToHandleConsent(null);
    setIsDialogOpenUndo(false); // Close the dialog
  };

  const handleConfirmDeclineConsentUndo = () => {
    handleActions(Number(sessionToHandleConsent), NMS_STATUS.IN_PROGRESS);
    setIsDialogOpenUndo(false); // Close the dialog after confirmation
    setSessionToHandleConsent(null);
  };

  const handleCancelDeclineConsentUndo = () => {
    console.log('Action cancelled.');
    setIsDialogOpenUndo(false); // Close the dialog after cancellation
    setSessionToHandleConsent(null);
  };

  const handleOpenConsentDialogDecline = id => {
    setSessionToHandleConsent(id);
    setIsDialogOpenDecline(true); // Open the dialog
  };

  const handleCloseConsentDialogDecline = () => {
    setSessionToHandleConsent(null);
    setIsDialogOpenDecline(false); // Close the dialog
  };

  const handleConfirmDeclineConsentDecline = () => {
    handleActions(Number(sessionToHandleConsent), NMS_STATUS.NO_CONSENT);
    setIsDialogOpenDecline(false); // Close the dialog after confirmation
    setSessionToHandleConsent(null);
  };

  const handleCancelDeclineConsentDecline = () => {
    console.log('Action cancelled.');
    setIsDialogOpenDecline(false); // Close the dialog after cancellation
    setSessionToHandleConsent(null);
  };

  const followUpAction = item => {
    return (
      <>
        {moment().startOf('day') >= moment(item?.followUpdDate).startOf('day') ? (
          <>
            <Tooltip title={"Let's Start"}>
              <IconButton
                onClick={() => {
                  handleSessionConsent(item.id);
                }}
                style={{
                  backgroundColor: '',
                  marginLeft: 0,
                  marginRight: 5,
                  height: 25,
                  width: 25,
                  // border: '1px solid lightgray',
                  padding: '10px',
                  display: 'flex',
                  borderRadius: 100,
                  // justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  className={'actions_css'}
                  src={'../../../../content/assets/check.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                />{' '}
              </IconButton>
            </Tooltip>
            <Tooltip title="Not Contactable">
              <IconButton
                onClick={() => {
                  followUpNotContactableToCompleteAction(item.id, NMS_STATUS.COMPLETED);
                }}
                style={{
                  // backgroundColor: '#246EBA',
                  marginLeft: 0,
                  marginRight: 5,
                  height: 25,
                  width: 25,
                  // border: '1px solid lightgray',
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 100,
                  // justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  className={'actions_css'}
                  src={'../../../../content/assets/notreachable.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <span style={{ textAlign: 'center', color: 'grey', justifyContent: 'center' }}>
            Follow-up Start from-{moment(item?.followUpdDate).format('DD-MM-YYYY')}
          </span>
        )}
      </>
    );
  };

  const InterventionAction = item => {
    return (
      <>
        {moment() > moment(item?.nextSession).add(rule, 'days') ? (
          <>
            <Tooltip title={"Let's Start"}>
              <IconButton
                onClick={() => {
                  handleSessionConsent(item.id);
                }}
                style={{
                  backgroundColor: '',
                  marginLeft: 0,
                  marginRight: 5,
                  height: 25,
                  width: 25,
                  // border: '1px solid lightgray',
                  padding: '10px',
                  display: 'flex',
                  borderRadius: 100,
                  // justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  className={'actions_css'}
                  src={'../../../../content/assets/check.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                />{' '}
              </IconButton>
            </Tooltip>

            {item.sessionStatus.id !== NMS_STATUS.NO_CONSENT && (
              <Tooltip title="No Consent">
                <IconButton
                  onClick={() => {
                    handleActions(item.id, NMS_STATUS.NO_CONSENT);
                  }}
                  style={{
                    backgroundColor: '',
                    marginLeft: 0,
                    marginRight: 5,
                    height: 25,
                    width: 25,
                    // border: '1px solid lightgray',
                    padding: '10px',
                    display: 'flex',
                    borderRadius: 100,
                    // justifyContent: 'space-around',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img
                    className={'actions_css'}
                    src={'../../../../content/assets/noconcent.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {item.sessionStatus.id !== NMS_STATUS.NO_REACHABLE && (
              <Tooltip title={'Not Contactable' + ' - ' + item.notStartedCallAttempt + ' Call Attempts '}>
                <IconButton
                  onClick={() => {
                    handleActions(item.id, NMS_STATUS.NO_REACHABLE, item.notStartedCallAttempt);
                  }}
                  style={{
                    // backgroundColor: '#246EBA',
                    marginLeft: 0,
                    marginRight: 5,
                    height: 25,
                    width: 25,
                    // border: '1px solid lightgray',
                    padding: '0px',
                    display: 'flex',
                    borderRadius: 100,
                    // justifyContent: 'space-around',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img
                    className={'actions_css'}
                    src={'../../../../content/assets/notreachable.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {item.sessionStatus.id === NMS_STATUS.NO_REACHABLE && (
              <Tooltip title={item.callAttempt + ' Call Attempts'}>
                <IconButton
                  onClick={() => {
                    handleCallAttempt(item.id, item.callAttempt);
                  }}
                  style={{
                    // backgroundColor: '#246EBA',
                    marginLeft: 8,
                    marginRight: 5,
                    height: 25,
                    width: 25,
                    // border: '1px solid lightgray',
                    padding: '0px',
                    display: 'flex',
                    borderRadius: 100,
                    // justifyContent: 'space-around',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img
                    className={'actions_css'}
                    src={'../../../../content/assets/miss-call-icon.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                  />
                  <sup
                    style={{
                      backgroundColor: 'lightgreen',
                      padding: '5px',
                      paddingTop: '8px',
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%',
                      display: 'inline-block',
                    }}
                  >
                    <div style={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>{item.callAttempt}</div>
                  </sup>
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Incorrect Number">
              <IconButton
                onClick={() => {
                  handleActions(item.id, NMS_STATUS.INCORRECT_NUMBER);
                }}
                style={{
                  // backgroundColor: '#246EBA',
                  marginLeft: 0,
                  marginRight: 5,
                  height: 25,
                  width: 25,
                  // border: '1px solid lightgray',
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 100,
                  // justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  className={'actions_css'}
                  src={'../../../../content/assets/incorrect_number.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Call Back">
              <IconButton
                onClick={() => {
                  handleClickOpenCallBack(item?.id);
                }}
                style={{
                  // backgroundColor: '#246EBA',
                  marginLeft: 0,
                  marginRight: 5,
                  height: 25,
                  width: 25,
                  // border: '1px solid lightgray',
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 100,
                  // justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  className={'actions_css'}
                  src={'../../../../content/assets/call-back.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <span style={{ textAlign: 'center', color: 'grey', justifyContent: 'center' }}>
            Intervention Start from-{moment(item?.nextSession).add(rule, 'days').format('DD-MM-YYYY')}
          </span>
        )}
      </>
    );
  };

  const NotStartedInterventionAction = item => {
    return (
      <>
        <Tooltip title={"Let's Start"}>
          <IconButton
            onClick={() => {
              letsStart(item.id);
            }}
            style={{
              backgroundColor: '',
              marginLeft: 0,
              marginRight: 5,
              height: 25,
              width: 25,
              // border: '1px solid lightgray',
              padding: '10px',
              display: 'flex',
              borderRadius: 100,
              // justifyContent: 'space-around',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img
              className={'actions_css'}
              src={'../../../../content/assets/check.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
            />{' '}
          </IconButton>
        </Tooltip>

        {item.sessionStatus.id !== NMS_STATUS.NO_CONSENT && (
          <Tooltip title="No Consent">
            <IconButton
              onClick={() => {
                handleActions(item.id, NMS_STATUS.NO_CONSENT);
              }}
              style={{
                backgroundColor: '',
                marginLeft: 0,
                marginRight: 5,
                height: 25,
                width: 25,
                // border: '1px solid lightgray',
                padding: '10px',
                display: 'flex',
                borderRadius: 100,
                // justifyContent: 'space-around',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img
                className={'actions_css'}
                src={'../../../../content/assets/noconcent.png'} // Use the image URL from the array
                alt="logo"
                style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
              />
            </IconButton>
          </Tooltip>
        )}

        {item.sessionStatus.id !== NMS_STATUS.NO_REACHABLE && (
          <Tooltip title={'Not Contactable' + ' - ' + item.notStartedCallAttempt + ' Call Attempts '}>
            <IconButton
              onClick={() => {
                handleCallAttempt(item.id, item.notStartedCallAttempt);
              }}
              style={{
                // backgroundColor: '#246EBA',
                marginLeft: 0,
                marginRight: 5,
                height: 25,
                width: 25,
                // border: '1px solid lightgray',
                padding: '0px',
                display: 'flex',
                borderRadius: 100,
                // justifyContent: 'space-around',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img
                className={'actions_css'}
                src={'../../../../content/assets/notreachable.png'} // Use the image URL from the array
                alt="logo"
                style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
              />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Incorrect Number">
          <IconButton
            onClick={() => {
              handleActions(item.id, NMS_STATUS.INCORRECT_NUMBER);
            }}
            style={{
              // backgroundColor: '#246EBA',
              marginLeft: 0,
              marginRight: 5,
              height: 25,
              width: 25,
              // border: '1px solid lightgray',
              padding: '0px',
              display: 'flex',
              borderRadius: 100,
              // justifyContent: 'space-around',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img
              className={'actions_css'}
              src={'../../../../content/assets/incorrect_number.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const NotContactableAction = item => {
    return (
      <>
        <Tooltip title={"Let's Start"}>
          <IconButton
            onClick={() => {
              handleSessionConsent(item.id);
            }}
            style={{
              backgroundColor: '',
              marginLeft: 0,
              marginRight: 5,
              height: 25,
              width: 25,
              // border: '1px solid lightgray',
              padding: '10px',
              display: 'flex',
              borderRadius: 100,
              // justifyContent: 'space-around',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img
              className={'actions_css'}
              src={'../../../../content/assets/check.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
            />{' '}
          </IconButton>
        </Tooltip>
        <Tooltip title="No Consent">
          <IconButton
            onClick={() => {
              handleActions(item.id, NMS_STATUS.NO_CONSENT);
            }}
            style={{
              backgroundColor: '',
              marginLeft: 0,
              marginRight: 5,
              height: 25,
              width: 25,
              // border: '1px solid lightgray',
              padding: '10px',
              display: 'flex',
              borderRadius: 100,
              // justifyContent: 'space-around',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img
              className={'actions_css'}
              src={'../../../../content/assets/noconcent.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Incorrect Number">
          <IconButton
            className={'actions_css'}
            onClick={() => {
              handleActions(item.id, NMS_STATUS.INCORRECT_NUMBER);
            }}
            style={{
              // backgroundColor: '#246EBA',
              marginLeft: 0,
              marginRight: 5,
              height: 25,
              width: 25,
              // border: '1px solid lightgray',
              padding: '0px',
              display: 'flex',
              borderRadius: 100,
              // justifyContent: 'space-around',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img
              src={'../../../../content/assets/incorrect_number.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '24px', marginRight: 0, marginLeft: 0 }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const canPerformActionsIntervention = item => {
    // Return false if any appUserIntervention or appUserFollowUp does not match currentAppUser.id
    const hasNonMatchingUserIntervention =
      item?.prescription?.prescriptionMedicines
        ?.filter(med => med.appUserIntervention !== null)
        .every(medicine => medicine?.appUserIntervention?.id === currentAppUser?.id) &&
      item?.prescription?.prescriptionMedicines.length >= 1;
    return hasNonMatchingUserIntervention;
  };

  const canPerformActionsFollowUp = item => {
    const hasNonMatchingUserFollowUp =
      item?.prescription?.prescriptionMedicines
        ?.filter(med => med.appUserFollowUp !== null)
        .every(medicine => medicine?.appUserFollowUp?.id === currentAppUser?.id) && item?.prescription?.prescriptionMedicines.length >= 1;

    // If a non-matching user is found, return false; otherwise, return true
    return hasNonMatchingUserFollowUp;
  };
  const { showMenu } = useDashboardContext();
  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      item
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: 35,
        position: 'fixed',
        right: '2%',
        maxWidth: '-webkit-fill-available',
        marginLeft: showMenu ? '6%' : '17%',
        transition: 'ease 0.5s',
      }}
    >
      {selectedColor === 'seven' ? (
        <>
          <Grid container item xs={12} spacing={2} style={{ marginBottom: '10px' }}>
            <Grid item xs={2.2}>
              <Tooltip title="Date From">
                <div style={{ display: 'flex' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      onChange={newValue => handledateFrom(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </Tooltip>
            </Grid>

            <Grid item xs={2.2}>
              <Tooltip title="Date Till">
                <div style={{ display: 'flex' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      // value={searchCreteria.to === '' ? null : searchCreteria.to}
                      className="clock mui-field-design-filter"
                      format="DD/MM/YYYY"
                      onChange={newValue => handledateTill(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid lg={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} item style={{ height: 30, marginTop: 5, marginBottom: '26px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '135ch' }}>
              <OutlinedInput
                style={{ borderRadius: 'none', height: 45, width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 }, maxLength: 50 }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
            <Tooltip title="Refresh">
              <IconButton
                onClick={() => handleRefresh()}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px', marginRight: '10px' }} />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Paper style={{ width: '100%', border: 'none' }}>
          <TableContainer style={{ maxHeight: '74vh', border: 'none' }} ref={tableContainerRef} onScroll={handleScroll}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead sx={{ height: 30, border: 'none' }}>
                <TableRow
                  sx={{
                    height: 30,
                    border: 'none',
                    borderTop: '0.1px solid #dadce1',
                    borderBottom: '0.1px solid #dadce1',
                  }}
                >
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>ID</div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      width: '11%',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Person2OutlinedIcon style={{ marginRight: 5, height: '18px', color: '#7CB6F8' }} />
                      Patient Name
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      width: '10%',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DateRangeOutlinedIcon style={{ marginRight: 5, height: '18px', color: '#7CB6F8' }} />
                      Date Of Birth
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      width: '12%',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ContactsOutlinedIcon style={{ marginRight: 5, height: '18px', color: '#7CB6F8' }} />
                      Primary Contact
                    </div>
                  </TableCell>
                  {/*<TableCell*/}
                  {/*  sx={{*/}
                  {/*    backgroundColor: '#F5F6FA',*/}
                  {/*    fontSize: 13,*/}
                  {/*    fontWeight: 'bold',*/}
                  {/*    height: 30,*/}
                  {/*    fontFamily: 'Roboto, sans-serif',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                  {/*    <ContactsOutlinedIcon style={{ marginRight: 5, height: '18px' }} />*/}
                  {/*    Secondary Contact*/}
                  {/*  </div>*/}
                  {/*</TableCell>*/}
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PinDropIcon style={{ marginRight: 5, height: '18px', color: '#FA0019' }} />
                      Address
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MedicationOutlinedIcon style={{ marginRight: 5, height: '18px', color: '#2AB465' }} />
                      Medicines
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      width: '14%',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DateRangeOutlinedIcon style={{ marginRight: 5, height: '18px', color: '#7CB6F8' }} />
                      Date Of Engagement
                    </div>
                  </TableCell>

                  <TableCell
                    colSpan={3}
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      textAlign: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PendingActionsOutlinedIcon style={{ height: '18px', color: '#ECB80D' }} />
                      Actions
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      textAlign: 'center',
                    }}
                  >
                    <Tooltip title={'Sort'}>
                      <FilterListIcon
                        onClick={handleClickSort}
                        style={{
                          marginRight: 5,
                          height: 30,
                          width: 20,
                          padding: '0px',
                          display: 'flex',
                          borderRadius: 5,
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <div>
                    <Menu anchorEl={anchorElSort} open={Boolean(anchorElSort)} onClose={handleCloseSort}>
                      <MenuItem>
                        <Checkbox size="small" checked={selectedItems.address} onChange={handleCheckboxChange} name="address" />
                        <ListItemText primary="Address" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox size="small" checked={selectedItems.fullName} onChange={handleCheckboxChange} name="fullName" />
                        <ListItemText primary="Patient Name" />
                      </MenuItem>
                      {/*<MenuItem>*/}
                      {/*  <Checkbox size="small"*/}
                      {/*  checked={selectedItems.nextSession}*/}
                      {/*  onChange={handleCheckboxChange}*/}
                      {/*  name="nextSession"*/}
                      {/*  />*/}
                      {/*  <ListItemText primary="Date of Engagement" />*/}
                      {/* </MenuItem>*/}
                    </Menu>
                  </div>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionByPharmacyEntities &&
                  sessionByPharmacyEntities.length > 0 &&
                  sessionByPharmacyEntities.map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="complementary"
                      style={{
                        border: 'none',
                        borderTop: '0.1px solid #dadce1',
                        borderBottom: '0.1px solid #dadce1',
                        backgroundColor: index % 2 === 0 ? 'white' : '#F9F9F9', // Alternating colors
                      }}
                    >
                      <TableCell style={{ fontSize: 12 }}>{index + 1}</TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item.patient && item.patient.firstName}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item.patient['dateofBirth'] && formatDate(item.patient.dateofBirth)}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item?.prevSessionType?.id === NMS_TYPES.INTERVENTION && item.sessionStatus?.id === NMS_STATUS.NOT_STARTED
                          ? moment().isSameOrAfter(item?.callBackDate)
                            ? item.patient && item.patient.telephone
                            : '***********'
                          : item?.patient?.telephone}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item.patient && item.patient.address}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item &&
                          item.prescription &&
                          item.prescription.prescriptionMedicines &&
                          item.prescription.prescriptionMedicines.length > 0 &&
                          item?.prescription?.prescriptionMedicines?.map(pm => pm?.medicine?.name).join(', ')}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {
                          // item?.sessionType?.id === NMS_TYPES.FOLLOW_UP
                          // ? formatDate(item?.followUpdDate) :
                          item?.nextSession && formatDate(item?.nextSession)
                        }
                      </TableCell>

                      <TableCell sx={{ textAlign: 'center' }}>
                        {item?.sessionStatus?.id !== NMS_STATUS.COMPLETED ? (
                          // canPerformActions(item) ? (
                          <div style={{ justifyContent: 'center', display: 'flex', alignSelf: 'center' }}>
                            {item?.sessionStatus?.id === NMS_STATUS.NO_REACHABLE ? (
                              NotContactableAction(item)
                            ) : item?.prevSessionType?.id === NMS_TYPES.INTERVENTION &&
                              item.sessionStatus?.id === NMS_STATUS.IN_PROGRESS ? (
                              canPerformActionsIntervention(item) ? (
                                InterventionAction(item)
                              ) : (
                                <Tooltip
                                  title={
                                    'Medicine reviewed by some other pharmacist at intervention stage you cannot perform action on that.'
                                  }
                                >
                                  <LockPersonIcon style={{ color: 'lightgray', height: 30, width: 30 }} />
                                </Tooltip>
                              )
                            ) : item?.prevSessionType?.id === NMS_TYPES.FOLLOW_UP && item.sessionStatus?.id === NMS_STATUS.IN_PROGRESS ? (
                              canPerformActionsFollowUp(item) ? (
                                followUpAction(item)
                              ) : (
                                <Tooltip
                                  title={'Medicine reviewed by some other pharmacist at follow-up stage you cannot perform action on that.'}
                                >
                                  <LockPersonIcon style={{ color: 'lightgray', height: 30, width: 30 }} />
                                </Tooltip>
                              )
                            ) : item?.prevSessionType?.id === NMS_TYPES.INTERVENTION && item.sessionStatus?.id === NMS_STATUS.NO_CONSENT ? (
                              item.noConsentBy === null ? (
                                <span style={{ textAlign: 'center', color: 'rgb(212, 52, 52)', justifyContent: 'center' }}>
                                  Consent Declined
                                </span>
                              ) : (
                                <IconButton onClick={() => handleOpenConsentDialogUndo(item.id)} style={{ color: 'red' }}>
                                  <SettingsBackupRestoreIcon />
                                </IconButton>
                              )
                            ) : item.sessionStatus?.id === NMS_STATUS.INCORRECT_NUMBER ? (
                              <span style={{ textAlign: 'center', color: 'rgb(212, 52, 52)', justifyContent: 'center' }}>
                                Incorrect Number
                              </span>
                            ) : item?.prevSessionType?.id === NMS_TYPES.INTERVENTION &&
                              item.sessionStatus?.id === NMS_STATUS.NOT_STARTED ? (
                              canPerformActionsIntervention(item) ? (
                                moment().isSameOrAfter(item?.callBackDate) ? (
                                  NotStartedInterventionAction(item)
                                ) : (
                                  'Call back patient at ' + moment(item.callBackDate).format('DD-MM-YYYY hh:mm a')
                                )
                              ) : (
                                <Tooltip
                                  title={
                                    'Medicine reviewed by some other pharmacist at intervention stage you cannot perform action on that.'
                                  }
                                >
                                  <LockPersonIcon style={{ color: 'lightgray', height: 30, width: 30 }} />
                                </Tooltip>
                              )
                            ) : (
                              '-'
                            )}
                          </div>
                        ) : item?.completedBy === null ? (
                          <span style={{ textAlign: 'center', color: 'rgb(33, 173, 100)', justifyContent: 'center' }}>Completed</span>
                        ) : (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(item.id)}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Edit.png'}
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}

                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 150, 300]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </Paper>
      </Grid>
      <Dialog open={openCallback} onClose={handleCloseCallBack}>
        <DialogTitle>Call Back Request</DialogTitle>
        <DialogContent>
          <TextField
            label="Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={callBackValues.date}
            onChange={e => setValuesCallBack('date', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.date}
            helperText={errorsCallBack.date}
          />
          <TextField
            label="Time"
            type="time"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={callBackValues.time}
            onChange={e => setValuesCallBack('time', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.time}
            helperText={errorsCallBack.time}
          />
          <TextField
            label="Comment"
            multiline
            rows={3}
            fullWidth
            value={callBackValues.comment}
            onChange={e => setValuesCallBack('comment', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.comment}
            helperText={errorsCallBack.comment}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCallBack}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmitCallBack(patientId)}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            Submit
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Your session will be soft deleted after 3 call attempts.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {/* Dialog for confirmation */}
        <Dialog open={isDialogOpenUndo} onClose={handleCloseConsentDialogUndo}>
          <DialogTitle>WARNING!</DialogTitle>
          <DialogContent>
            Are you sure you want to perform this action, this patient has declined consent to participate in the NMS Service?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelDeclineConsentUndo}
              variant="contained"
              color="error"
              sx={{
                backgroundColor: '#E90016',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
              No
            </Button>
            <Button
              onClick={() => handleConfirmDeclineConsentUndo()}
              autoFocus
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              Yes
              <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {/* Dialog for confirmation */}
        <Dialog open={isDialogOpenDecline} onClose={handleCloseConsentDialogDecline}>
          <DialogTitle>ATTENTION!</DialogTitle>
          <DialogContent>Are you sure this patient has declined consent to participate in the NMS Service?</DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelDeclineConsentDecline}
              variant="contained"
              color="error"
              sx={{
                backgroundColor: '#E90016',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
              No
            </Button>

            <Button
              onClick={() => handleConfirmDeclineConsentDecline()}
              autoFocus
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              Yes
              <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Grid>
  );
};
export default TableCommponent;
