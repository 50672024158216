import React, { useCallback, useEffect, useState } from 'react';
import '../pharmacist-work-flow/pharmacist.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import {
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Slide,
  Box,
  InputLabel,
  ListSubheader,
  Backdrop,
  CircularProgress,
  TextareaAutosize,
  Autocomplete,
  Modal,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import sessionPreviouslyDeliveredNoType, {
  getEntities as getPreviouslyDeliveredNoType,
} from 'app/entities/session-previously-delivered-no-type/session-previously-delivered-no-type.reducer';
import sessionMedicineUsedAsPrescribedNoType, {
  getEntities as getSessionMedicineUsedAsPrescribedNoType,
} from 'app/entities/session-medicine-used-as-prescribed-no-type/session-medicine-used-as-prescribed-no-type.reducer';
import patientReportedIssueType, {
  getEntities as getPatientReportedIssueType,
} from 'app/entities/patient-reported-issue-type/patient-reported-issue-type.reducer';
import adviceToPatientType, {
  getEntities as adviceToPatientProvided,
} from 'app/entities/advice-to-patient-type/advice-to-patient-type.reducer';
import patientAgreedActionType, {
  getEntities as getPatientAgreedActionType,
} from 'app/entities/patient-agreed-action-type/patient-agreed-action-type.reducer';
import pharmacistAgreedActionType, {
  getEntities as getPharmacistAgreedActionType,
} from 'app/entities/pharmacist-agreed-action-type/pharmacist-agreed-action-type.reducer';
import sessionConsultationMethod, {
  getEntities as getConsultationMethod,
} from 'app/entities/session-consultation-method/session-consultation-method.reducer';
import healthyLivingAdviceType, {
  getEntities as getHealthyLivingAdvice,
} from 'app/entities/healthy-living-advice-type/healthy-living-advice-type.reducer';
import {
  getEntity as getCurrentSession,
  getMedicineConditions,
  getPharmacistAgreedActionReferBackTypes,
  getPharmacyById,
  interventionNotDeliveredSession,
  referGp,
  reviewInEligibleSession,
  reviewSession,
  reviewSessionMedicine,
} from 'app/entities/session/session.reducer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import WarningIcon from '@mui/icons-material/Warning'; // Importing the warning icon
import { getEntity as getCurrentAppUser } from 'app/entities/app-user/app-user.reducer';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import { NMS_STATUS, NMS_TYPES } from 'app/config/constants';
import { TransitionProps } from '@mui/material/transitions';
import sessionDetail from 'app/entities/session/session-detail';
import { ethnicGroups } from 'app/shared/util/utits';
import { getEntities as getProvidedInformationType } from 'app/entities/patient-provided-information-type/patient-provided-information-type.reducer';
import { CheckCircle, DoDisturb, Done, RadioButtonUnchecked } from '@mui/icons-material';
import pharmacistDetailPageHook from 'app/modules/pharmacist-work-flow/hooks/pharmacistDetailPageHook';
import { resetSessionPrev } from 'app/shared/reducers/authentication';
import BlankSheetFormComp from '../deo-work-flow/session-deo-blanksheet-form-comp';
import { BlankSheetFormCompAdmin } from './session-deo-blanksheet-form-comp';
import { getAllServicePharmacist } from './admin-reducer';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Pharmacist {
  firstName: string;
  // Add any other properties here that you expect in the pharmacist object
  lastName?: string;
  id?: string;
}
interface IReviewSessionMedicine {
  session_id: number | string;
  medicine_id: number | string;
  prescription_medicine_id: number | string;
  medicineUsedAsPrescribed: boolean;
  sessionMedicineUsedAsPrescribedNoType: number | string | null;
  patientReportedIssues: Array<any>;
  adviceToPatient: Array<any>;
  adviceToPatientOther: number | string | null;
  patientAgreedAction: Array<any>;
  agreedPharmacistAction: Array<any>;
  healthyLivingAdvice: Array<any>;
  pharmacistAgreedReferBack: number | string | null;
  pharmacistAgreedReferBackId: number | string | null;
  agreedPharmacistActionOther: number | string | null;
  healthyLivingAdviceOther: number | string | null;
  interventionNote: number | string | null;
  patientReportedIssuesOther: number | string | null;
  pharmacistAgreedReferBackValue: number | string | null;
  pharmacistAgreedReferBackOther: number | string | null;
  patientAgreedActionOther: number | string | null;
  is_eligible_condition: unknown;
  pharmacistToAssign?: any;
}
interface IGPDetail {
  session_id: number | string;
  name: string;
  email: string;
  telephone: string;
  address: string;
  gPPracticeName: string;
  referDetail: string;
  referDetailComments: string;
  referDetailTypes: Array<any>;
  referralComment: string;
}
interface IReviewSession {
  session_id: number | string | null;
  sessionStatus: number | string | null;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  nextSession: Date;
  nextSessionTime: Date;
  sessionConsultationMethod: any;
  ethnicityOfPatient: any;
  ethnicityOfPatientOther: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}

interface IReviewInEligibleSession extends IReviewSessionMedicine {
  session_id: number | string;
  medicine_id: number | string;
  prescription_medicine_id: number | string;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  sessionConsultationMethod: any;
  ethnicityOfPatient: any;
  ethnicityOfPatientOther: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}

interface IReviewInterventionNotDeliveredSession extends IReviewSessionMedicine {
  session_id: number | string;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  sessionConsultationMethod: any;
  ethnicityOfPatientOther: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}
// eslint-disable-next-line complexity
export const CXFormCompAdmin = () => {
  const { states, handlers } = pharmacistDetailPageHook();
  const { handleClickOpenCallBack, handleCloseCallBack, handleSubmitCallBack, setValuesCallBack } = handlers;
  const { openCallback, errorsCallBack, callBackValues } = states;
  const initialsValues = {
    prescription: '',
    medicines: [],
    patientDetail: '',
    GpDetail: '',
  };
  const [fieldError, setFieldError] = useState({
    previouslyDelivered: true,
    sessionPreviouslyDeliveredNoType: false,
    sessionPreviouslyDeliveredOtherReason: false,
    sessionConsultationMethod: true,
    ethnicityOfPatient: true,
    ethnicityOfPatientOther: false,
    lastModifiedDate: false,
    lastModifiedDateTime: false,
  });
  const [gpFieldError, setGpFieldError] = useState({});
  const [gpFieldErrorMessage, setGpFieldErrorMessage] = useState({ email: '', telephone: '' });
  const [fieldErrorFollowUp, setFieldErrorFollowUp] = useState({
    followUp: false,
    followUpDeclined: false,
    declinedReason: false,
    notDeclinedByPatient: false,
    nextSession: true,
    nextSessionTime: true,
  });
  const [fieldErrorMedicine, setFieldErrorMedicine] = useState({
    medicineUsedAsPrescribed: true,
    is_eligible_condition: true,
    sessionMedicineUsedAsPrescribedNoType: false,
    patientReportedIssues: true,
    patientProvidedInformation: true,
    patientReportedIssuesOther: false,
    adviceToPatient: true,
    medicineConditions: true,
    adviceToPatientOther: false,
    patientAgreedAction: true,
    patientAgreedActionOther: false,
    agreedPharmacistAction: true,
    agreedPharmacistActionOther: false,
    pharmacistAgreedReferBack: false,
    pharmacistAgreedReferBackOther: false,
    healthyLivingAdvice: true,
    healthyLivingAdviceOther: false,
    interventionNote: true,
    otherNotes: true,
    newMedicine: true,
  });
  const [currentSessionDetail, setCurrentSessionDetail] = useState(initialsValues);
  const [totalMedicineCount, setTotalMedicineCount] = useState(0);
  const [reviewMedicineCount, setReviewMedicineCount] = useState(0);
  const [unReviewedMedicine, setUnReviewedMedicine] = useState(0);
  const [newMedicineReviewed, setNewMedicineReviewed] = useState(0);
  const [showFollowUpDetails, setShowFollowUpDetails] = useState(false);
  const [adviceProvidedFollowUp, setAdviceProvidedFollowUp] = useState(false);
  const [referredToGpFollowUp, setReferredToGpFollowUp] = useState(false);

  const [showMedicineRelatedContent, setShowMedicineRelatedContent] = useState(false);
  const [buttonClicked, setButtonClicked] = useState([]);
  const [buttonClickedNotNew, setButtonClickedNotNew] = useState([]);
  const [buttonClickedNotEligible, setButtonClickedNotEligible] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [methodofConsultation, setMethodofConsultation] = useState('');
  const [selectedAdvice, setSelectedAdvice] = useState([]);
  const [referred_back_to_the_prescriber, setReferred_back_to_the_prescriber] = useState({});
  const [agreedPharmacistActions, setAgreedPharmacistActions] = useState([]);
  const [agreedPatientActions, setAgreedPatientActions] = useState([]);
  const [advice_provided, setAdvice_provided] = useState([]);
  const [outcome_of_discussions_with_patient_Value, setOutcome_of_discussions_with_patient_Value] = useState();
  const [issues_reported_by_the_patient_Value, setIssues_reported_by_the_patient_Value] = useState([]);
  const [patient_provided_information_value, setPatient_provided_information_value] = useState([]);
  const [patient_reports_not_using_the_medicine_as_prescribed, setPatient_reports_not_using_the_medicine_as_prescribed] = useState('');
  const [patient_using_medicine_as_prescribed_value, setPatient_using_medicine_as_prescribed] = useState('');
  const [medicineUsedAsPrescribedFollowUp, setMedicineUsedAsPrescribedFollowUp] = useState(null);
  const [selectedOptionIntervention, setSelectedOptionIntervention] = useState(null);
  const [selectedFollowUp, setSelectedFollowUp] = useState('true');
  const [selectedFollowUpDeclined, setSelectedFollowUpDeclined] = useState({});
  const [selectedFollowUpNotDeclinedByPatient, setSelectedFollowUpNotDeclinedByPatient] = useState({});
  const [scrAccess, setScrAccess] = useState('true');
  const [selectedOption, setSelectedOption] = useState();
  const [currentTime, setCurrentTime] = useState('--:--');
  const [medicineName, setMedicineName] = useState(null);
  const [newMedicine, setNewMedicine] = useState(null);
  const [eligibleCondition, setEligibleCondition] = useState(null);
  const [prescriberStoppedMedication, setPrescriberStoppedMedication] = useState(false);
  const [medicineConditions, setMedicineConditions] = useState([]);
  const [selectMedicineConditions, setSelectMedicineConditions] = useState([]);
  const [showConditionsDropdown, setShowConditionsDropdown] = useState(false);
  const [selectNewMedicineConditions, setSelectNewMedicineConditions] = useState(null);
  const [sessionValues, setSessionValues] = useState({});
  const [gpValues, setGpValues] = useState({});
  const [sessionValuesMedicine, setSessionValuesMedicine] = useState({});
  const dispatch = useAppDispatch();
  const [dateTwo, setDateTwo] = useState(null);
  const [dateone, setDateone] = useState(null);
  const [timeone, setTimeone] = useState(null);
  const [timeTwo, setTimeTwo] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openGp, setOpenGp] = React.useState(false);
  const [showCount, setShowCount] = React.useState(0);
  const [declinedPromptShow, setDeclinedPromptShow] = useState(false);

  const [selectedOptionEthnic, setSelectedOptionEthnic] = useState('');
  const [showTextFieldEthnic, setShowTextFieldEthnic] = useState(false);
  const [notNewPopUp, setNotNewPopUp] = useState(false);
  const [notEligiblePopUp, setNotEligiblePopUp] = useState(false);
  const allServicesPharmacist = useAppSelector(state => state.admin.allServicePharmacist);

  const handleChangeEthnic = event => {
    const value = event.target.value;
    setSelectedOptionEthnic(value);
    setShowTextFieldEthnic(value === 'Any other ethnic group');
    setFormValues(event);
    if (value === 'Any other ethnic group') {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['ethnicityOfPatientOther']: true }));
    }
  };
  const handleChangeEthnicOther = event => {
    const value = event.target.value;
    setFormValues(event);
  };

  const { id } = useParams<'id'>();
  let rule = null;
  const storedRule = localStorage.getItem('pharmacy_rule');

  if (storedRule) {
    try {
      rule = JSON.parse(storedRule);
    } catch (error) {
      console.error('Failed to parse pharmacy_rule:', error);
    }
  } else {
    console.warn('No pharmacy_rule found in localStorage');
  }
  const navigate = useNavigate();
  const sessionEntity = useAppSelector(state => state.session.entity);
  const sessionEntityLoading = useAppSelector(state => state.session.loading);
  const pharmacistReferBackTypes = useAppSelector(state => state.session.pharmacistReferBackTypes);
  const currentUser = useAppSelector(state => state.authentication.account);
  const currentAppUser = useAppSelector(state => state.appUser.entity);
  const healthyLivingAdviceEntities = useAppSelector(state => state.healthyLivingAdviceType.entities);
  const consultationMethodEntities = useAppSelector(state => state.sessionConsultationMethod.entities);
  const unSortedEntities = useAppSelector(state => state.sessionPreviouslyDeliveredNoType.entities);
  const previouslyDeliveredNoTypeEntities = [...unSortedEntities].sort((a, b) => {
    const order = [1, 2, 3, 4, 5, 9, 7, 8, 6]; // Define the desired order
    return order.indexOf(a.id) - order.indexOf(b.id);
  });
  const medicineUsedAsPrescribedNoTypeEntities = useAppSelector(state => state.sessionMedicineUsedAsPrescribedNoType.entities);
  const patientReportedIssueTypeEntities = useAppSelector(state => state.patientReportedIssueType.entities);
  const patientProvideInformationTypeEntities = useAppSelector(state => state.patientProvidedInformationType.entities);
  const adviceToPatientTypeEntities = useAppSelector(state => state.adviceToPatientType.entities);
  const patientAgreedActionTypeEntities = useAppSelector(state => state.patientAgreedActionType.entities);
  const pharmacistAgreedActionTypeEntities = useAppSelector(state => state.pharmacistAgreedActionType.entities);
  const medicineConditionsList = useAppSelector(state => state.session.medicineConditionsList);
  const [interventionReferId, setInterventionReferId] = useState(null);
  const [followReferId, setFollowReferId] = useState(null);
  const [callBackCommentPopUp, setCallBackCommentPopUp] = useState(false);
  const [openWarningLost, setOpenWarningLost] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pharmacistToAssign, setPharmacistToAssign] = useState(null);

  useEffect(() => {
    setSessionValues(oldValues => ({ ...oldValues, ['session_id']: id }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['session_id']: id }));
  }, [id]);

  useEffect(() => {
    dispatch(getPharmacistAgreedActionReferBackTypes({}));
    dispatch(getCurrentAppUser(currentUser.id));
    dispatch(getHealthyLivingAdvice({}));
    dispatch(getConsultationMethod({}));
    dispatch(getPreviouslyDeliveredNoType({}));
    dispatch(getSessionMedicineUsedAsPrescribedNoType({}));
    dispatch(getPatientReportedIssueType({}));
    dispatch(adviceToPatientProvided({}));
    dispatch(getPatientAgreedActionType({}));
    dispatch(getProvidedInformationType({}));
    dispatch(getPharmacistAgreedActionType({}));
    dispatch(getMedicineConditions());
    dispatch(getAllServicePharmacist());
  }, []);

  useEffect(() => {
    localStorage.setItem('currentSessionType', sessionEntity?.prevSessionType?.id);
    localStorage.setItem('currentSessionId', sessionEntity?.id);
  }, [sessionEntity]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const currentSession = useCallback(async () => {
    await dispatch(getCurrentSession(id));
  }, [id]);

  useEffect(() => {
    currentSession();
    console.log({ sessionEntityLoading });
  }, [id]);

  useEffect(() => {
    const prescriptionEntity = sessionEntity && sessionEntity.prescription;
    const medicinesEntities =
      sessionEntity &&
      sessionEntity.prescription &&
      sessionEntity.prescription.prescriptionMedicines &&
      sessionEntity.prescription.prescriptionMedicines;
    const patientDetailEntities = sessionEntity && sessionEntity.patient && sessionEntity.patient;
    const gpDetailEntities = sessionEntity && sessionEntity.gP && sessionEntity.gP;
    setCurrentSessionDetail({
      prescription: prescriptionEntity,
      medicines: medicinesEntities,
      patientDetail: patientDetailEntities,
      GpDetail: gpDetailEntities,
    });
    if (patientDetailEntities) {
      setGpValues({
        name: gpDetailEntities.name,
        email: gpDetailEntities.email,
        telephone: gpDetailEntities.telephone,
        address: gpDetailEntities.address,
        gPPracticeName: gpDetailEntities.gPPracticeName,
        referralComment: gpDetailEntities.referralComment,
        session_id: id,
      });
      setGpFieldError({
        // name: !gpDetailEntities.name,
        // email: !gpDetailEntities.email,
        address: !gpDetailEntities.address,
        gPPracticeName: !gpDetailEntities.gPPracticeName,
        referralComment: !gpDetailEntities.referralComment,
      });
    }
  }, [sessionEntity]);

  useEffect(() => {
    const referBackOptionsArray = [];
    pharmacistReferBackTypes.map((label, key) => referBackOptionsArray.push(label.name));
    setGpValues(oldValues => ({ ...oldValues, referDetailTypes: referBackOptionsArray }));
  }, [sessionEntity]);

  useEffect(() => {
    if (sessionEntity.prevSessionType && sessionEntity.prevSessionType.id) {
      setSessionValues(oldValues => ({ ...oldValues, ['sessionType']: sessionEntity.prevSessionType.id }));
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['sessionType']: sessionEntity.prevSessionType.id }));
    }
    if (sessionEntity.prevSessionType && sessionEntity.prevSessionType.id === 2) {
      setSessionValues(oldValues => ({ ...oldValues, ['followUp']: 'false' }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUp']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUpDeclined']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['declinedReason']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
    }
  }, [sessionEntity]);

  useEffect(() => {
    if (sessionEntity.sessionStatus?.id === NMS_STATUS.NOT_STARTED) {
      if (sessionEntity?.prescription.prescriptionMedicines?.some(medicine => medicine.review === true)) {
        setCallBackCommentPopUp(false);
      } else {
        setCallBackCommentPopUp(true);
      }
    } else {
      setCallBackCommentPopUp(false);
    }
  }, [sessionEntity?.sessionStatus?.id && sessionEntity?.prescription.prescriptionMedicines]);

  useEffect(() => {
    if (currentSessionDetail.prescription && currentSessionDetail.medicines && currentSessionDetail.medicines.length > 0) {
      const prescriptionMedicinesArray = currentSessionDetail.medicines;
      const totalMedicine = currentSessionDetail.medicines.length;
      const reviewedMedicines = prescriptionMedicinesArray.filter(
        pm =>
          pm.review === true &&
          sessionEntity['prevSessionType'] &&
          pm.sessionType !== null &&
          sessionEntity['prevSessionType']?.id === pm?.sessionType?.id
      );

      setTotalMedicineCount(totalMedicine);
      setReviewMedicineCount(reviewedMedicines.length);
      if (totalMedicine !== reviewedMedicines.length) {
        setShowFollowUpDetails(false);
      } else {
        setShowMedicineRelatedContent(false);
        setShowFollowUpDetails(true);
      }
    } else {
      setShowFollowUpDetails(false);
    }
  }, [currentSessionDetail]);

  useEffect(() => {
    setSessionValues(oldValues => ({ ...oldValues, ['scrAccess']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['followUp']: 'true' }));
  }, []);

  const handleLastNameChange = event => {
    setLastName(event.target.value); // Update the lastName state
  };

  const handleFirstNameChange = event => {
    setFirstName(event.target.value); // Update the firstName state
  };

  const handlePhoneNumberChange = event => {
    setPhoneNumber(event.target.value);
  };

  const handleRadioChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleCloseCommentPopUp = () => {
    setCallBackCommentPopUp(false);
  };

  const handleConsultationDetail = e => {
    setDateone(e);
    setSessionValues(oldValues => ({ ...oldValues, ['lastModifiedDate']: dayjs(e).format('YYYY-MM-DD') }));
    if (dayjs(e).format('YYYY-MM-DD') === undefined || dayjs(e).format('YYYY-MM-DD') === '' || dayjs(e).format('YYYY-MM-DD') === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['lastModifiedDate']: true }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['lastModifiedDate']: false }));
    }
  };

  const handleConsultationDetailTime = e => {
    setTimeone(e);
    setTimeTwo(e);
    setSessionValues(oldValues => ({ ...oldValues, ['lastModifiedDateTime']: dayjs(e).format('hh:mm a') }));
    setSessionValues(oldValues => ({ ...oldValues, ['nextSessionTime']: dayjs(e).format('hh:mm a') }));
    if (dayjs(e).format('hh:mm a') === undefined || dayjs(e).format('hh:mm a') === '' || dayjs(e).format('hh:mm a') === null) {
      setFieldError(oldValues => ({ ...oldValues, ['lastModifiedDateTime']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['nextSessionTime']: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['lastModifiedDateTime']: false }));
      setFieldError(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
    }
  };

  const isDateInBetweenCutOffDates = (date, cutOfDays) => {
    // Get current date
    const today = new Date();

    // Calculate the end of the previous month
    const prevMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

    // Calculate the cut-off dates
    const currentMonthCutOffDate = new Date(today.getFullYear(), today.getMonth(), cutOfDays);
    currentMonthCutOffDate.setDate(currentMonthCutOffDate.getDate());

    const prevMonthCutOffDate = new Date(prevMonthEndDate);
    prevMonthCutOffDate.setDate(prevMonthCutOffDate.getDate() - cutOfDays);

    // Check if the provided date is in between the cut-off dates
    return date >= prevMonthCutOffDate && date <= currentMonthCutOffDate;
  };

  const handleSelectPharmacist = (event: any, newValue: any) => {
    setPharmacistToAssign(newValue);
  };

  useEffect(() => {
    if (pharmacistToAssign !== null) {
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['pharmacistToAssign']: pharmacistToAssign,
      }));
      setSessionValues(oldValues => ({
        ...oldValues,
        ['pharmacistToAssign']: pharmacistToAssign,
      }));
    }
  }, [pharmacistToAssign]);

  const handleSetCurrentDateTime = () => {
    const currentDateTime = dayjs(); // Get the current date and time
    setTimeone(currentDateTime);
    setDateone(currentDateTime);
    setTimeTwo(currentDateTime);
    setSessionValues(oldValues => ({ ...oldValues, ['lastModifiedDate']: currentDateTime.format('YYYY-MM-DD') }));
    setSessionValues(oldValues => ({ ...oldValues, ['lastModifiedDateTime']: currentDateTime.format('hh:mm a') }));
    setSessionValues(oldValues => ({ ...oldValues, ['nextSessionTime']: currentDateTime.format('hh:mm a') }));

    setFieldError(oldValues => ({
      ...oldValues,
      lastModifiedDate: false,
      lastModifiedDateTime: false,
    }));
    setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
  };

  const handleSetSevenDaysDate = () => {
    const currentdate = dayjs();
    const futureDate = currentdate;
    setDateTwo(futureDate);
    setSessionValues(oldValues => ({ ...oldValues, ['nextSession']: futureDate.format('YYYY-MM-DD') }));
    if (futureDate.isValid()) {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: false }));
    }
  };

  const handleFollowUpDate = e => {
    setDateTwo(e);
    setSessionValues(oldValues => ({ ...oldValues, ['nextSession']: dayjs(e).format('YYYY-MM-DD') }));
    if (dayjs(e).format('YYYY-MM-DD') === undefined || dayjs(e).format('YYYY-MM-DD') === '' || dayjs(e).format('YYYY-MM-DD') === null) {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: true }));
    } else {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: false }));
    }
  };

  const handleFollowUpTime = e => {
    setTimeTwo(e);
    setSessionValues(oldValues => ({ ...oldValues, ['nextSessionTime']: dayjs(e).format('hh:mm a') }));
    if (dayjs(e).format('hh:mm a') === undefined || dayjs(e).format('hh:mm a') === '' || dayjs(e).format('hh:mm a') === null) {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: true }));
    } else {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
    }
  };

  const handle_referred_back_to_the_prescriber = e => {
    setReferred_back_to_the_prescriber(e.target.value);
    setSessionValuesMedicine(oldValues => ({
      ...oldValues,
      ['pharmacistAgreedReferBackValue']: e.target.closest('label').getAttribute('data-value'),
    }));
    setGpValues(oldValues => ({
      ...oldValues,
      ['referDetail']: e.target.closest('label').getAttribute('data-value'),
    }));
    if (e.target.name === 'pharmacistAgreedReferBack' && e.target.value === '10') {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackOther']: true }));
    } else {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackOther']: false }));
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackOther']: null }));
    }
    setFormValuesMedicine(e);
  };

  const handle_outcome_of_discussions_with_patient_Value_options = event => {
    setOutcome_of_discussions_with_patient_Value(event.target.value);
  };

  const handlepatient_reports_not_using_the_medicine_as_prescribedoptions = e => {
    setPatient_reports_not_using_the_medicine_as_prescribed(e.target.value);
    setFormValuesMedicine(e);
  };
  const handleNotUsedAsPrescribedOption = e => {
    if (e.target.value === '2' || e.target.value === '1') {
      setPrescriberStoppedMedication(true);
    } else {
      setPrescriberStoppedMedication(false);
    }
    setPatient_reports_not_using_the_medicine_as_prescribed(e.target.value);
    setFormValuesMedicine(e);
  };

  const handleIssueToggle = (type, issue, issueId, e) => {
    const issueStateMapping = {
      patientReportedIssues: issues_reported_by_the_patient_Value,
      patientProvidedInformation: patient_provided_information_value, // Example additional state for other checkbox groups
      // Add other types as needed
    };

    const setIssueStateMapping = {
      patientReportedIssues: setIssues_reported_by_the_patient_Value,
      patientProvidedInformation: setPatient_provided_information_value, // Example additional state setter
      // Add other types as needed
    };

    const currentIssues = issueStateMapping[type] || [];
    const setCurrentIssues = setIssueStateMapping[type];

    const issueExistsInObjects = currentIssues.some(report => report.value === issue);

    if (issueExistsInObjects) {
      const updatedIssues = currentIssues.filter(item => item.value !== issue);
      setCurrentIssues(updatedIssues);

      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        [type]: updatedIssues,
      }));

      if (updatedIssues.length === 0) {
        // Set fieldErrorMedicine to true if no checkboxes are checked
        setFieldErrorMedicine(oldValues => ({ ...oldValues, [type]: true }));
      }

      if (issue === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, [`${type}Other`]: false }));
      }
    } else {
      const newIssues = [...currentIssues, { id: issueId, value: issue }];
      setCurrentIssues(newIssues);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        [type]: newIssues,
      }));

      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));

      if (issue === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, [`${type}Other`]: true }));
      }
    }

    if (!currentIssues.some(report => report.value === 'Other')) {
      setSessionValuesMedicine(oldValues => ({ ...oldValues, [`${type}Other`]: null }));
    }
  };

  const handleMedicineConditionToggle = (condition, conditionId, e) => {
    const conditionExistsInObjects = selectMedicineConditions.some(report => report.value.includes(condition));
    if (conditionExistsInObjects) {
      setSelectMedicineConditions(selectMedicineConditions.filter(item => item.value !== condition));
    } else {
      setSelectMedicineConditions([...selectMedicineConditions, { id: conditionId, value: condition }]);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['medicineConditions']: [...selectMedicineConditions, { id: conditionId, value: condition }],
      }));
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const handleSelectNewMedicineCondition = e => {
    setSelectNewMedicineConditions(e.target.value);
    setSessionValuesMedicine(oldValues => ({
      ...oldValues,
      ['medicineConditions']: [...selectMedicineConditions, { id: e.target.value, value: '' }],
    }));
    setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
  };

  const handleAdviceToggle = (advice, adviceId, e) => {
    const adviceExistsInObjects = advice_provided.some(report => report.value === advice);

    if (adviceExistsInObjects) {
      const updatedAdvice = advice_provided.filter(item => item.value !== advice);
      setAdvice_provided(updatedAdvice);

      if (advice === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: false }));
      }

      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['adviceToPatient']: updatedAdvice,
      }));
    } else {
      const newAdvice = [...advice_provided, { id: adviceId, value: advice }];
      setAdvice_provided(newAdvice);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['adviceToPatient']: newAdvice,
      }));

      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));

      if (advice === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: true }));
      }
    }

    if (!advice_provided.some(report => report.value === 'Other')) {
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: null }));
    }
  };

  const handleAdviceProvidedFollowUp = e => {
    setAdviceProvidedFollowUp(!adviceProvidedFollowUp);
    setSessionValuesMedicine(oldValues => ({ ...oldValues, [e.target.name]: e.target.checked }));
    if (e.target.checked) {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['adviceToPatient']: true }));
    } else {
      setAdvice_provided([]);
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: null }));
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatient']: null }));
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: false }));
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['adviceToPatient']: false }));
    }
  };

  const handleReferredToGpFollowUp = e => {
    setReferredToGpFollowUp(!referredToGpFollowUp);
    if (e.target.checked) {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: true }));
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackId']: 6 }));
    } else {
      handlePharmacistAgreedReferBackReset();
    }
  };

  const handleAgreedPatientActions = (patientAgreedAction, patientAgreedActionId, e) => {
    const actionExistsInObjects = agreedPatientActions.some(report => report.value === patientAgreedAction);

    if (actionExistsInObjects) {
      const updatedActions = agreedPatientActions.filter(item => item.value !== patientAgreedAction);
      setAgreedPatientActions(updatedActions);

      if (patientAgreedAction === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['patientAgreedActionOther']: false }));
      }

      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['patientAgreedAction']: updatedActions,
      }));
    } else {
      const newActions = [...agreedPatientActions, { id: patientAgreedActionId, value: patientAgreedAction }];
      setAgreedPatientActions(newActions);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['patientAgreedAction']: newActions,
      }));

      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));

      if (patientAgreedAction === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['patientAgreedActionOther']: true }));
      }
    }

    if (!agreedPatientActions.some(report => report.value === 'Other')) {
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientAgreedActionOther']: null }));
    }
  };

  const handleAgreedPharmacistActions = (pharmacistAgreedActionValue, pharmacistAgreedActionId, e) => {
    const actionExistsInObjects = agreedPharmacistActions.some(report => report.value === pharmacistAgreedActionValue);

    if (actionExistsInObjects) {
      const updatedActions = agreedPharmacistActions.filter(item => item.value !== pharmacistAgreedActionValue);
      setAgreedPharmacistActions(updatedActions);

      if (pharmacistAgreedActionValue === 'Referred back to the prescriber') {
        handlePharmacistAgreedReferBackReset();
      }

      if (pharmacistAgreedActionValue === 'Other') {
        setSessionValuesMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: null }));
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: false }));
      }

      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['agreedPharmacistAction']: updatedActions,
      }));
    } else {
      const newActions = [...agreedPharmacistActions, { id: pharmacistAgreedActionId, value: pharmacistAgreedActionValue }];
      setAgreedPharmacistActions(newActions);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['agreedPharmacistAction']: newActions,
      }));

      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));

      if (pharmacistAgreedActionValue === 'Referred back to the prescriber') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: true }));
        setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackId']: 6 }));
      }

      if (pharmacistAgreedActionValue === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: true }));
      }
    }
  };

  const handleHealthyLivingAdvice = (healthyLivingAdvice, healthyLivingAdviceId, e) => {
    const adviceExistsInObjects = selectedAdvice.some(report => report.value === healthyLivingAdvice);

    if (adviceExistsInObjects) {
      const updatedAdvice = selectedAdvice.filter(item => item.value !== healthyLivingAdvice);
      setSelectedAdvice(updatedAdvice);

      if (healthyLivingAdvice === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: false }));
        setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: null }));
      }

      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['healthyLivingAdvice']: updatedAdvice,
      }));
    } else {
      const newAdvice = [...selectedAdvice, { id: healthyLivingAdviceId, value: healthyLivingAdvice }];
      setSelectedAdvice(newAdvice);
      setSessionValuesMedicine(oldValues => ({
        ...oldValues,
        ['healthyLivingAdvice']: newAdvice,
      }));

      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));

      if (healthyLivingAdvice === 'Other') {
        setFieldErrorMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: true }));
      }
    }

    if (!selectedAdvice.some(report => report.value === 'Other')) {
      setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: null }));
    }
  };

  const handlesetPatient_using_medicine_as_prescribed = e => {
    setPatient_using_medicine_as_prescribed(e.target.value);
    if (e.target.name === 'medicineUsedAsPrescribed' && e.target.value === 'false') {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: true }));
    } else {
      handleMedicineUsedAsPrescribeNoTypeReset();
      setPrescriberStoppedMedication(false);
    }
    setFormValuesMedicine(e);
  };
  const handle_set_is_eligible_condition = e => {
    setEligibleCondition(e.target.value);
    setFormValuesMedicine(e);
  };

  const handleMedicinePrescribedFollowUp = e => {
    const { value } = e.target;
    if (medicineUsedAsPrescribedFollowUp === value) {
      setMedicineUsedAsPrescribedFollowUp(null);
    } else {
      setMedicineUsedAsPrescribedFollowUp(value);
    }
    if (value === 'false') {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: true }));
    } else {
      handleMedicineUsedAsPrescribeNoTypeReset();
    }
    setFormValuesMedicine(e);
  };

  const handleInterventionDeliveredOptionChange = e => {
    setSelectedOptionIntervention(e.target.value);
    if (e.target.name === 'previouslyDelivered' && e.target.value === 'false') {
      setFieldError(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredNoType']: true }));
    } else {
      handleInterventionDeliveredReset();
    }
    setFormValues(e);
  };

  const handleFollowUpOptionChange = e => {
    setSelectedFollowUp(e.target.value);
    if (e.target.name === 'followUp' && e.target.value === 'false') {
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUpDeclined']: true }));
    } else {
      if (timeTwo === undefined || timeTwo === null || timeTwo === '') {
        setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: true }));
      } else {
        setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSessionTime']: false }));
      }
      if (dateTwo === undefined || dateTwo === null || dateTwo === '') {
        setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: true }));
      } else {
        setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['nextSession']: false }));
      }
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUpDeclined']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['declinedReason']: false }));
      setSessionValues(oldValues => ({ ...oldValues, ['followUpDeclined']: null }));
      setSessionValues(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: null }));
      setSessionValues(oldValues => ({ ...oldValues, ['declinedReason']: '' }));
      setSelectedFollowUpDeclined({});
      setDeclinedPromptShow(false);
    }
    setFormValues(e);
    setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUp']: false }));
  };
  const handleFollowUpDeclined = e => {
    setSelectedFollowUpDeclined(e.target.value);
    if (e.target.value === 'true') {
      setDeclinedPromptShow(true);
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['declinedReason']: true }));
      setSelectedFollowUpNotDeclinedByPatient('');
      setSessionValues(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: null }));
    } else {
      setDeclinedPromptShow(false);
      setSessionValues(oldValues => ({ ...oldValues, ['declinedReason']: '' }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: true }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['declinedReason']: false }));
    }
    setFormValues(e);
    setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['followUpDeclined']: false }));
  };

  const handleNotDeclinedByPatient = e => {
    setSelectedFollowUpNotDeclinedByPatient(e.target.value);
    setFormValues(e);
    setFieldErrorFollowUp(oldValues => ({ ...oldValues, ['notDeclinedByPatient']: false }));
  };

  const handleScrAccess = e => {
    setScrAccess(e.target.value);
    setFormValues(e);
  };

  const handleOptionChangeReason = e => {
    setSelectedOption(e.target.value);
    if (e.target.name === 'sessionPreviouslyDeliveredNoType' && e.target.value === '6') {
      setFieldError(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: true }));
    } else {
      setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: null }));
      setFieldError(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: false }));
    }
    setFormValues(e);
  };

  const handlemethodofConsultation = e => {
    setMethodofConsultation(e.target.value);
    setFormValues(e);
  };

  const handleNewMedicine = e => {
    setNewMedicine(e.target.value);
    setEligibleCondition(null);
    setPrescriberStoppedMedication(false);
    setSessionValuesMedicine(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['is_eligible_condition']: null }));
    setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
    setFieldErrorMedicine(oldValues => ({ ...oldValues, ['is_eligible_condition']: true }));
    if (e.target.value === 'false') {
      handleMedicineReviewResetOnNewMedicineFalse();
    }
  };

  const setFormValues = e => {
    setSessionValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    checkSingleFieldValidations(e);
  };

  const setGpFormValues = e => {
    checkGpSingleFieldValidations(e); // Check validation first
    setGpValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const setFormValuesMedicine = e => {
    if (e.target.name === 'pharmacistAgreedReferBackOther') {
      setGpValues(oldValues => ({
        ...oldValues,
        ['referDetailComments']: e.target.value,
      }));
    }
    setSessionValuesMedicine(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    checkSingleFieldValidationsMedicine(e);
  };

  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldErrorFollowUp(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };
  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateTelephone = telephone => {
    // Simple regex for telephone validation, adjust pattern as needed
    const phoneRegex = /^[+]?[\d\s()-]{7,15}$/;
    return phoneRegex.test(telephone);
  };
  const checkGpSingleFieldValidations = e => {
    const { name, value } = e.target;

    if (value === undefined || value === '' || value === null) {
      setGpFieldError(oldValues => ({ ...oldValues, [name]: true }));
    } else if (name === 'email') {
      if (!validateEmail(value)) {
        setGpFieldError(oldValues => ({ ...oldValues, [name]: true }));
        setGpFieldErrorMessage(oldValues => ({ ...oldValues, [name]: 'Email must be valid!' }));
      } else {
        setGpFieldError(oldValues => ({ ...oldValues, [name]: false }));
        setGpFieldErrorMessage(oldValues => ({ ...oldValues, [name]: '' }));
      }
    } else if (name === 'telephone') {
      if (!validateTelephone(value)) {
        setGpFieldError(oldValues => ({ ...oldValues, [name]: true }));
        setGpFieldErrorMessage(oldValues => ({ ...oldValues, [name]: 'Telephone must be valid!' }));
      } else {
        setGpFieldError(oldValues => ({ ...oldValues, [name]: false }));
        setGpFieldErrorMessage(oldValues => ({ ...oldValues, [name]: '' }));
      }
    } else {
      setGpFieldError(oldValues => ({ ...oldValues, [name]: false }));
      setGpFieldErrorMessage(oldValues => ({ ...oldValues, [name]: '' }));
    }
  };

  const checkSingleFieldValidationsMedicine = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldErrorMedicine(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };
  const handleMedicineReviewPrescriberStoppedMedication = () => {
    if (checkFormValueValidation() && sessionValuesMedicine['medicine_id'] && sessionValuesMedicine['prescription_medicine_id']) {
      const data = {
        ...sessionValuesMedicine,
        is_eligible_condition: 'false',
      };
      const result = dispatch(reviewSessionMedicine(data as IReviewSessionMedicine))
        .then(() => {
          toast.success('Medicine successfully reviewed!');
          handleMedicineReviewReset();
        })
        .catch(error => {});
      setShowMedicineRelatedContent(false);
    } else {
      toast.error('Please fill all required options!');
    }
  };

  const handleMedicineReview = () => {
    if (pharmacistToAssign !== null) {
      if (sessionValuesMedicine['sessionType'] === NMS_TYPES.INTERVENTION) {
        if (sessionValuesMedicine['newMedicine'] === 'true' && sessionValuesMedicine['is_eligible_condition'] === 'true') {
          if (
            checkFormValueValidationMedicine() &&
            checkFormValueValidation() &&
            sessionValuesMedicine['medicine_id'] &&
            sessionValuesMedicine['prescription_medicine_id']
          ) {
            const result = dispatch(reviewSessionMedicine(sessionValuesMedicine as IReviewSessionMedicine))
              .then(() => {
                toast.success('Medicine successfully reviewed!');
                handleMedicineReviewReset();
              })
              .catch(error => {});
            setShowMedicineRelatedContent(false);
          } else {
            toast.error('Please fill all required options!');
          }
        } else {
          console.log({ sessionValuesMedicine, validations: checkFormValueValidation() });
          if (checkFormValueValidation() && sessionValuesMedicine['medicine_id'] && sessionValuesMedicine['prescription_medicine_id']) {
            const result = dispatch(reviewSessionMedicine(sessionValuesMedicine as IReviewSessionMedicine))
              .then(() => {
                if (sessionValuesMedicine['newMedicine'] === 'false' || sessionValuesMedicine['is_eligible_condition'] === 'false') {
                  toast.success('Medicine disposed of successfully!');
                } else {
                  toast.success('Medicine successfully reviewed!');
                }
                handleMedicineReviewReset();
                setNotNewPopUp(false);
                setNotEligiblePopUp(false);
              })
              .catch(error => {});
            setShowMedicineRelatedContent(false);
          } else {
            toast.error('Please fill all required options!');
          }
        }
      } else if (sessionValuesMedicine['sessionType'] === NMS_TYPES.FOLLOW_UP) {
        if (
          checkFormValueValidationForFollowUp() &&
          sessionValuesMedicine['medicine_id'] &&
          sessionValuesMedicine['prescription_medicine_id'] &&
          !fieldErrorMedicine['otherNotes']
        ) {
          const result = dispatch(reviewSessionMedicine(sessionValuesMedicine as IReviewSessionMedicine))
            .then(() => {
              toast.success('Medicine successfully reviewed!');
              handleMedicineReviewReset();
            })
            .catch(error => {
              toast.error(error);
            });
          setShowMedicineRelatedContent(false);
        } else {
          toast.error('Please fill all required options!');
        }
      }

      const medicineIdToRemove = Number(sessionValuesMedicine['prescription_medicine_id']);

      // Get the stored arrays from localStorage
      const interventionReferIds = JSON.parse(localStorage.getItem('interventionReferIds')) || [];
      const followReferIds = JSON.parse(localStorage.getItem('followReferIds')) || [];

      // Filter out the specific ID
      const updatedInterventionReferIds = interventionReferIds.filter(Rid => Rid !== medicineIdToRemove);
      const updatedFollowReferIds = followReferIds.filter(RFid => RFid !== medicineIdToRemove);

      // Save the updated arrays back to localStorage
      localStorage.setItem('interventionReferIds', JSON.stringify(updatedInterventionReferIds));
      localStorage.setItem('followReferIds', JSON.stringify(updatedFollowReferIds));

      // Update the state if needed
      setInterventionReferId(updatedInterventionReferIds);
      setFollowReferId(updatedFollowReferIds);
    } else {
      toast.error('Please select pharmacist to assign!');
    }
  };

  const handleInEligibleMedicineReview = () => {
    const mergedSessionValues = {
      ...sessionValues,
      ...sessionValuesMedicine,
      is_eligible_condition: 'false',
    };

    if (checkFormValueValidation() && sessionValuesMedicine['medicine_id'] && sessionValuesMedicine['prescription_medicine_id']) {
      const result = dispatch(reviewInEligibleSession(mergedSessionValues as IReviewInEligibleSession))
        .then(() => {
          navigate('/');
          toast.success('Session completed successfully!');
        })
        .catch(error => {});
      setShowMedicineRelatedContent(false);
    } else {
      toast.error('Please fill all required options!');
    }
  };

  const handleInterventionNotDeliveredSession = () => {
    const mergedSessionValues = {
      ...sessionValues,
    };
    if (checkFormValueValidation()) {
      const result = dispatch(interventionNotDeliveredSession(mergedSessionValues as IReviewInterventionNotDeliveredSession))
        .then(() => {
          navigate('/');
          toast.success('Session closed successfully!');
        })
        .catch(error => {});
    } else {
      toast.error('Please fill all required options!');
    }
  };

  const handleSessionSubmit = () => {
    if (pharmacistToAssign !== null) {
      if (sessionValuesMedicine['sessionType'] === NMS_TYPES.INTERVENTION) {
        if (checkFormValueValidation() && checkFollowUpDateValidation()) {
          const result = dispatch(reviewSession(sessionValues as IReviewSession))
            .then(() => {
              navigate('/admin/dashboard', { state: { selectedColor: 'three', index: 3 } });
              toast.success('Session completed successfully!');
            })
            .catch(error => {});
        } else {
          toast.error('Please fill all required options!');
        }
      } else if (sessionValuesMedicine['sessionType'] === NMS_TYPES.FOLLOW_UP) {
        if (checkFormValueValidationForFollowUp()) {
          const result = dispatch(reviewSession(sessionValues as IReviewSession))
            .then(() => {
              navigate('/admin/dashboard', { state: { selectedColor: 'three', index: 3 } });
              toast.success('Session completed successfully!');
            })
            .catch(error => {});
        } else {
          toast.error('Please fill all required options!');
        }
      }
    } else {
      toast.error('Please select pharmacist to assign!');
    }
  };

  const handleGpSubmit = () => {
    if (
      // gpValues['email'] &&
      // gpValues['telephone'] &&
      gpValues['address'] &&
      gpValues['gPPracticeName'] &&
      gpValues['referralComment'] &&
      gpValues['referDetail'] !== undefined &&
      gpValues['referDetail'] !== null &&
      gpValues['referDetail'] !== '' &&
      Object.values(gpFieldError).every(value => value === false)
    ) {
      const data = {
        ...gpValues,
        prescriptionMedicine: sessionValuesMedicine['prescription_medicine_id'],
      };
      // @ts-ignore
      const result = dispatch(referGp(data))
        .then(res => {
          toast.success(res?.payload['data']['message']);
          const referToGpId = Number(res?.payload['data']['referToGp']);
          const followReferedId = Number(res?.payload['data']['referToGpFollow']);

          // Get existing arrays from localStorage
          const interventionReferIds = JSON.parse(localStorage.getItem('interventionReferIds')) || [];
          const followReferIds = JSON.parse(localStorage.getItem('followReferIds')) || [];

          // Add the new IDs to the arrays
          if (!interventionReferIds.includes(referToGpId)) {
            interventionReferIds.push(referToGpId);
          }
          if (!followReferIds.includes(followReferedId)) {
            followReferIds.push(followReferedId);
          }

          // Save the updated arrays back to localStorage
          localStorage.setItem('interventionReferIds', JSON.stringify(interventionReferIds));
          localStorage.setItem('followReferIds', JSON.stringify(followReferIds));

          setInterventionReferId(interventionReferIds);
          setFollowReferId(followReferIds);
        })
        .catch(error => {
          toast.error(error);
        });
      handleCloseGp();
    } else {
      toast.error('Please fill in all required options!');
    }
  };

  const checkFormValueValidation = () => {
    if (
      !fieldError['previouslyDelivered'] &&
      !fieldError['sessionPreviouslyDeliveredNoType'] &&
      !fieldError['sessionPreviouslyDeliveredOtherReason'] &&
      !fieldError['sessionConsultationMethod'] &&
      !fieldError['ethnicityOfPatient'] &&
      !fieldError['ethnicityOfPatientOther'] &&
      !fieldError['lastModifiedDate'] &&
      !fieldError['lastModifiedDateTime']
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkFormValueValidationForFollowUp = () => {
    if (!fieldError['sessionConsultationMethod'] && !fieldError['lastModifiedDate'] && !fieldError['lastModifiedDateTime']) {
      return true;
    } else {
      return false;
    }
  };

  const checkFollowUpDateValidation = () => {
    if (
      !fieldErrorFollowUp['nextSession'] &&
      !fieldErrorFollowUp['followUp'] &&
      !fieldErrorFollowUp['followUpDeclined'] &&
      !fieldErrorFollowUp['notDeclinedByPatient'] &&
      !fieldErrorFollowUp['declinedReason']
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkFormValueValidationMedicine = () => {
    if (
      !fieldErrorMedicine['medicineUsedAsPrescribed'] &&
      !fieldErrorMedicine['sessionMedicineUsedAsPrescribedNoType'] &&
      !fieldErrorMedicine['patientReportedIssues'] &&
      !fieldErrorMedicine['patientProvidedInformation'] &&
      !fieldErrorMedicine['patientReportedIssuesOther'] &&
      // !fieldErrorMedicine['adviceToPatient'] &&
      !fieldErrorMedicine['adviceToPatientOther'] &&
      !fieldErrorMedicine['patientAgreedAction'] &&
      !fieldErrorMedicine['patientAgreedActionOther'] &&
      !fieldErrorMedicine['agreedPharmacistAction'] &&
      !fieldErrorMedicine['agreedPharmacistActionOther'] &&
      !fieldErrorMedicine['pharmacistAgreedReferBack'] &&
      !fieldErrorMedicine['pharmacistAgreedReferBackOther'] &&
      !fieldErrorMedicine['healthyLivingAdvice'] &&
      !fieldErrorMedicine['healthyLivingAdviceOther'] &&
      !fieldErrorMedicine['interventionNote']
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkFormValueValidationMedicineForFollowUp = () => {
    if (
      !fieldErrorMedicine['medicineUsedAsPrescribed'] &&
      !fieldErrorMedicine['sessionMedicineUsedAsPrescribedNoType'] &&
      !fieldErrorMedicine['adviceToPatient'] &&
      !fieldErrorMedicine['adviceToPatientOther'] &&
      !fieldErrorMedicine['agreedPharmacistAction'] &&
      !fieldErrorMedicine['agreedPharmacistActionOther'] &&
      !fieldErrorMedicine['pharmacistAgreedReferBack'] &&
      !fieldErrorMedicine['pharmacistAgreedReferBackOther']
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleButtonClick = (key, prescriptionId, medicineConditionsArray, event) => {
    const prescriptionMedicinesArray = currentSessionDetail.medicines;
    const unReviewedMedicines = prescriptionMedicinesArray.filter(pm => pm.review === false);
    const medicineReviewed = prescriptionMedicinesArray.filter(pm => pm.review === true);
    const newMedicineReviewedCount = medicineReviewed.filter(pm => pm.sessionStatus === null);
    console.log({ unReviewedMedicines, medicineReviewed });
    setNewMedicineReviewed(newMedicineReviewedCount.length);
    setUnReviewedMedicine(unReviewedMedicines.length);
    setMedicineConditions(medicineConditionsArray);

    if (medicineConditionsArray.length === 0) {
      setShowConditionsDropdown(true);
    }

    setButtonClicked(prevClicked => {
      const updatedClicked = [...prevClicked];
      updatedClicked.forEach((_, keys) => {
        updatedClicked[keys] = false;
      });
      updatedClicked[key] = true;
      setShowMedicineRelatedContent(updatedClicked.includes(true));
      return updatedClicked;
    });
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['medicine_id']: key }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['prescription_medicine_id']: prescriptionId }));
    const currentMedicineName = event.currentTarget.getAttribute('data-medicine-name');
    setMedicineName(currentMedicineName);
  };

  const handleButtonClickNotNew = (key, prescriptionId, medicineConditionsArray, event) => {
    const prescriptionMedicinesArray = currentSessionDetail.medicines;
    const unReviewedMedicines = prescriptionMedicinesArray.filter(pm => pm.review === false);
    const medicineReviewed = prescriptionMedicinesArray.filter(pm => pm.review === true);
    const newMedicineReviewedCount = medicineReviewed.filter(pm => pm.sessionStatus === null);
    setNewMedicineReviewed(newMedicineReviewedCount.length);
    setUnReviewedMedicine(unReviewedMedicines.length);
    setMedicineConditions(medicineConditionsArray);
    console.log({ unReviewedMedicines, medicineReviewed });
    if (medicineConditionsArray.length === 0) {
      setShowConditionsDropdown(true);
    }

    setButtonClickedNotNew(prevClicked => {
      const updatedClicked = [...prevClicked];
      updatedClicked.forEach((_, keys) => {
        updatedClicked[keys] = false;
      });
      updatedClicked[key] = true;
      setShowMedicineRelatedContent(updatedClicked.includes(true));
      return updatedClicked;
    });
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['medicine_id']: key }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['prescription_medicine_id']: prescriptionId }));

    setSessionValues(oldValues => ({ ...oldValues, ['scrAccess']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['previouslyDelivered']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['ethnicityOfPatient']: 'Prefer not to say' }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionConsultationMethod']: '2' }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionType']: 1 }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['newMedicine']: 'false' }));
    setSessionValues(oldValues => ({ ...oldValues, ['followUp']: 'true' }));

    const currentMedicineName = event.currentTarget.getAttribute('data-medicine-name');
    setMedicineName(currentMedicineName);
    setNotNewPopUp(true);
    setNotEligiblePopUp(false);
    setFieldError({
      previouslyDelivered: false,
      sessionPreviouslyDeliveredNoType: false,
      sessionPreviouslyDeliveredOtherReason: false,
      sessionConsultationMethod: false,
      ethnicityOfPatient: false,
      ethnicityOfPatientOther: false,
      lastModifiedDate: false,
      lastModifiedDateTime: false,
    });
  };

  const handleCloseNotNew = () => {
    setNotNewPopUp(false);
    setFieldError({
      previouslyDelivered: true,
      sessionPreviouslyDeliveredNoType: true,
      sessionPreviouslyDeliveredOtherReason: false,
      sessionConsultationMethod: true,
      ethnicityOfPatient: true,
      ethnicityOfPatientOther: false,
      lastModifiedDate: false,
      lastModifiedDateTime: false,
    });
    setShowMedicineRelatedContent(false);
    setSelectedOptionIntervention(null);
    handleInterventionDeliveredReset();
    setMethodofConsultation('');
    setSelectedOptionEthnic('');
  };

  const handleCloseNotEligible = () => {
    setNotEligiblePopUp(false);
    setFieldError({
      previouslyDelivered: true,
      sessionPreviouslyDeliveredNoType: true,
      sessionPreviouslyDeliveredOtherReason: false,
      sessionConsultationMethod: true,
      ethnicityOfPatient: true,
      ethnicityOfPatientOther: false,
      lastModifiedDate: false,
      lastModifiedDateTime: false,
    });
    setShowMedicineRelatedContent(false);
    setSelectedOptionIntervention(null);
    handleInterventionDeliveredReset();
    setMethodofConsultation('');
    setSelectedOptionEthnic('');
  };

  const handleButtonClickNotEligible = (key, prescriptionId, medicineConditionsArray, event) => {
    const prescriptionMedicinesArray = currentSessionDetail.medicines;
    const unReviewedMedicines = prescriptionMedicinesArray.filter(pm => pm.review === false);
    const medicineReviewed = prescriptionMedicinesArray.filter(pm => pm.review === true);
    const newMedicineReviewedCount = medicineReviewed.filter(pm => pm.sessionStatus === null);
    setNewMedicineReviewed(newMedicineReviewedCount.length);
    setUnReviewedMedicine(unReviewedMedicines.length);
    setMedicineConditions(medicineConditionsArray);

    if (medicineConditionsArray.length === 0) {
      setShowConditionsDropdown(true);
    }

    setButtonClickedNotEligible(prevClicked => {
      const updatedClicked = [...prevClicked];
      updatedClicked.forEach((_, keys) => {
        updatedClicked[keys] = false;
      });
      updatedClicked[key] = true;
      setShowMedicineRelatedContent(updatedClicked.includes(true));
      return updatedClicked;
    });
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['medicine_id']: key }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['prescription_medicine_id']: prescriptionId }));

    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['is_eligible_condition']: 'false' }));
    setSessionValues(oldValues => ({ ...oldValues, ['scrAccess']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['previouslyDelivered']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['ethnicityOfPatient']: 'Prefer not to say' }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionConsultationMethod']: '2' }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionType']: 1 }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['newMedicine']: 'true' }));
    setSessionValues(oldValues => ({ ...oldValues, ['followUp']: 'true' }));

    const currentMedicineName = event.currentTarget.getAttribute('data-medicine-name');
    setMedicineName(currentMedicineName);
    setNotNewPopUp(false);
    setNotEligiblePopUp(true);
    setFieldError({
      previouslyDelivered: false,
      sessionPreviouslyDeliveredNoType: false,
      sessionPreviouslyDeliveredOtherReason: false,
      sessionConsultationMethod: false,
      ethnicityOfPatient: false,
      ethnicityOfPatientOther: false,
      lastModifiedDate: false,
      lastModifiedDateTime: false,
    });
  };

  function customTheme(
    outerTheme: any
  ):
    | Partial<import('@mui/private-theming').DefaultTheme>
    | ((outerTheme: import('@mui/private-theming').DefaultTheme) => import('@mui/private-theming').DefaultTheme) {
    throw new Error('Function not implemented.');
  }
  const formatDate = patientDate => {
    return moment(patientDate).format('DD-MM-YYYY');
  };
  const handleDashboardRedirect = () => {
    navigate('/');
  };

  const handleInterventionDeliveredReset = () => {
    setSelectedOption(null);
    setFieldError(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredNoType']: false }));
    setFieldError(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: false }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: null }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredNoType']: null }));
  };
  const handleMedicineUsedAsPrescribeNoTypeReset = () => {
    setPatient_reports_not_using_the_medicine_as_prescribed(null);
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: null }));
    setFieldErrorMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: false }));
  };
  const handlePharmacistAgreedReferBackReset = () => {
    setReferred_back_to_the_prescriber(null);
    setFieldErrorMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: false }));
    setFieldErrorMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: false }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackId']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackValue']: null }));
  };

  const handleMedicineReviewReset = () => {
    setPatient_reports_not_using_the_medicine_as_prescribed(null);
    setReferred_back_to_the_prescriber(null);
    setPatient_using_medicine_as_prescribed('');
    setIssues_reported_by_the_patient_Value([]);
    setPatient_provided_information_value([]);
    setAgreedPharmacistActions([]);
    setAdvice_provided([]);
    setSelectMedicineConditions([]);
    setSelectedAdvice([]);
    setAgreedPatientActions([]);
    setNewMedicine(null);
    setEligibleCondition(null);
    setPrescriberStoppedMedication(false);
    setSelectNewMedicineConditions(null);
    setMedicineUsedAsPrescribedFollowUp(false);
    setAdviceProvidedFollowUp(false);
    setReferredToGpFollowUp(false);

    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackValue']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdvice']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['interventionNote']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistAction']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientAgreedActionOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientAgreedAction']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['selectMedicineConditions']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientReportedIssuesOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientReportedIssues']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientProvidedInformation']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackId']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['otherNotes']: null }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: null }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredNoType']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['newMedicine']: null }));
    setFieldErrorMedicine({
      medicineUsedAsPrescribed: true,
      is_eligible_condition: true,
      sessionMedicineUsedAsPrescribedNoType: false,
      patientReportedIssues: true,
      patientProvidedInformation: true,
      patientReportedIssuesOther: false,
      adviceToPatient: true,
      medicineConditions: true,
      adviceToPatientOther: false,
      patientAgreedAction: true,
      patientAgreedActionOther: false,
      agreedPharmacistAction: true,
      agreedPharmacistActionOther: false,
      pharmacistAgreedReferBack: false,
      pharmacistAgreedReferBackOther: false,
      healthyLivingAdvice: true,
      healthyLivingAdviceOther: false,
      interventionNote: true,
      otherNotes: true,
      newMedicine: true,
    });
  };

  const handleMedicineReviewResetOnNewMedicineFalse = () => {
    setPatient_reports_not_using_the_medicine_as_prescribed(null);
    setReferred_back_to_the_prescriber(null);
    setPatient_using_medicine_as_prescribed('');
    setIssues_reported_by_the_patient_Value([]);
    setAgreedPharmacistActions([]);
    setAdvice_provided([]);
    setSelectMedicineConditions([]);
    setSelectNewMedicineConditions(null);
    setEligibleCondition(null);
    setPrescriberStoppedMedication(false);
    setSelectedAdvice([]);
    setAgreedPatientActions([]);

    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackValue']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdvice']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['healthyLivingAdviceOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['interventionNote']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistAction']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['agreedPharmacistActionOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientAgreedActionOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientAgreedAction']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatient']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['selectMedicineConditions']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['adviceToPatientOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['patientReportedIssuesOther']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBack']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['pharmacistAgreedReferBackId']: null }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredOtherReason']: null }));
    setSessionValues(oldValues => ({ ...oldValues, ['sessionPreviouslyDeliveredNoType']: null }));
    setSessionValuesMedicine(oldValues => ({ ...oldValues, ['sessionMedicineUsedAsPrescribedNoType']: null }));
    setFieldErrorMedicine({
      medicineUsedAsPrescribed: true,
      is_eligible_condition: true,
      sessionMedicineUsedAsPrescribedNoType: false,
      patientReportedIssues: true,
      patientProvidedInformation: true,
      patientReportedIssuesOther: false,
      adviceToPatient: true,
      medicineConditions: true,
      adviceToPatientOther: false,
      patientAgreedAction: true,
      patientAgreedActionOther: false,
      agreedPharmacistAction: true,
      agreedPharmacistActionOther: false,
      pharmacistAgreedReferBack: false,
      pharmacistAgreedReferBackOther: false,
      healthyLivingAdvice: true,
      healthyLivingAdviceOther: false,
      interventionNote: false,
      otherNotes: false,
      newMedicine: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setShowCount(1);
  };
  const handleCloseGp = () => {
    setOpenGp(false);
  };

  const handleOpen = () => {
    if (showCount === 0) setOpen(true);
  };
  const handleOpenGp = () => {
    console.log({ gpValues, gpFieldError });
    if (
      // gpValues['email'] &&
      // gpValues['telephone'] &&
      gpValues['address'] &&
      gpValues['gPPracticeName'] &&
      gpValues['referralComment'] &&
      Object.values(gpFieldError).every(value => value === false)
    ) {
      setOpenGp(true);
    }
  };

  const followUpHtml = () => {
    return (
      <>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="medicineUsedAsPrescribed"
                size="small"
                value={'true'}
                checked={medicineUsedAsPrescribedFollowUp === 'true'}
                onChange={handleMedicinePrescribedFollowUp}
                disabled={medicineUsedAsPrescribedFollowUp === 'false' || adviceProvidedFollowUp === true || referredToGpFollowUp === true}
              />
            }
            label="Patient using medicine as prescribed"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="medicineUsedAsPrescribed"
                size="small"
                value={'false'}
                checked={medicineUsedAsPrescribedFollowUp === 'false'}
                onChange={handleMedicinePrescribedFollowUp}
                disabled={medicineUsedAsPrescribedFollowUp === 'true'}
              />
            }
            label="Patient not using medicine as prescribed"
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          {medicineUsedAsPrescribedFollowUp === 'false' && (
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={patient_reports_not_using_the_medicine_as_prescribed}
                  name="sessionMedicineUsedAsPrescribedNoType"
                  style={{ marginTop: 5 }}
                  onChange={handlepatient_reports_not_using_the_medicine_as_prescribedoptions}
                >
                  {medicineUsedAsPrescribedNoTypeEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                      value={label.id}
                      control={<Radio size="small" />}
                      label={label.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="adviceProvided"
                size="small"
                value={adviceProvidedFollowUp}
                checked={adviceProvidedFollowUp}
                onChange={handleAdviceProvidedFollowUp}
                disabled={referredToGpFollowUp === true}
              />
            }
            label="Issues identified and discussed (advice give)"
          />
          <div style={{ marginLeft: '10px' }}>
            {adviceProvidedFollowUp && (
              <div>
                <FormGroup>
                  {adviceToPatientTypeEntities.map((label, key) => (
                    <FormControlLabel
                      key={key}
                      style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                      control={
                        <Checkbox
                          size="small"
                          name={'adviceToPatient'}
                          checked={advice_provided.some(report => report.value.includes(label.name))}
                          onChange={e => {
                            handleAdviceToggle(label.name, label.id, e);
                          }}
                        />
                      }
                      label={label.name}
                    />
                  ))}
                </FormGroup>

                {advice_provided.some(report => report.value.includes('Other')) ? (
                  <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                    <OutlinedInput
                      style={{
                        border: fieldErrorMedicine['adviceToPatientOther'] ? '1px solid red' : '1px solid lightgray',
                        borderRadius: 5,
                        height: 35,
                        width: 600,
                        marginTop: 0,
                      }}
                      name={'adviceToPatientOther'}
                      placeholder="Please Indicate"
                      onChange={setFormValuesMedicine}
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </FormControl>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="referredToGp"
                size="small"
                value={referredToGpFollowUp}
                checked={referredToGpFollowUp}
                onChange={handleReferredToGpFollowUp}
                disabled={medicineUsedAsPrescribedFollowUp === 'true'}
              />
            }
            label="Issue identified referred to GP"
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          {referredToGpFollowUp && (
            <div>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name={'pharmacistAgreedReferBack'}
                    value={referred_back_to_the_prescriber}
                    onChange={handle_referred_back_to_the_prescriber}
                    style={{ marginTop: 5 }}
                  >
                    {pharmacistReferBackTypes &&
                      pharmacistReferBackTypes.length > 0 &&
                      pharmacistReferBackTypes.map((label, key) => (
                        <FormControlLabel
                          key={key}
                          style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                          value={label.id}
                          data-value={label.name}
                          control={<Radio size="small" />}
                          label={label.name}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>

              {referred_back_to_the_prescriber === '10' && (
                <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                  <OutlinedInput
                    style={{
                      borderRadius: 5,
                      height: 35,
                      width: 600,
                      marginTop: 0,
                    }}
                    placeholder="Please Indicate"
                    name={'pharmacistAgreedReferBackOther'}
                    inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    onChange={setFormValuesMedicine}
                  />
                </FormControl>
              )}

              <div style={{ height: 200, backgroundColor: 'white', marginTop: 20 }}>
                <h2 className={'questionair-header-css'}>Patient Registered GP Details: </h2>
                <>
                  <Grid container spacing={3} sx={{ paddingBottom: '3px' }}>
                    <Grid item xs={4}>
                      <OutlinedInput
                        className="outline"
                        placeholder="GP Practice Name"
                        name="gPPracticeName"
                        fullWidth
                        value={gpValues ? gpValues['gPPracticeName'] : ''}
                        onChange={setGpFormValues}
                        sx={{
                          maxWidth: 'none',
                          border:
                            (referredToGpFollowUp && gpValues['gPPracticeName'] === '') || gpValues['gPPracticeName'] === null
                              ? '1px solid red'
                              : '',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ paddingBottom: '3px' }}>
                    <Grid item xs={4}>
                      <OutlinedInput
                        // className="outline"
                        placeholder="Address"
                        name="address"
                        fullWidth
                        value={gpValues ? gpValues['address'] : ''}
                        onChange={setGpFormValues}
                        sx={{ border: referredToGpFollowUp && gpValues['address'] === '' ? '1px solid red' : '' }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <OutlinedInput
                        // className="outline"
                        placeholder="Referral comment"
                        name="referralComment"
                        fullWidth
                        value={gpValues ? gpValues['referralComment'] : ''}
                        onChange={setGpFormValues}
                        sx={{
                          border:
                            referredToGpFollowUp && (gpValues['referralComment'] === '' || !gpValues['referralComment'])
                              ? '1px solid red'
                              : '',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              {/* <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} /> */}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                    </Grid>
                  </Grid>

                  <div style={{ marginTop: 20 }}>
                    <Button
                      variant={'contained'}
                      onClick={handleOpenGp}
                      disabled={JSON.parse(localStorage.getItem('followReferIds'))?.some(
                        iid => iid === Number(sessionValuesMedicine['prescription_medicine_id'])
                      )}
                      style={{
                        background: JSON.parse(localStorage.getItem('followReferIds'))?.some(
                          iid => iid === Number(sessionValuesMedicine['prescription_medicine_id'])
                        )
                          ? 'grey'
                          : '',
                        height: 30,
                        width: 140,
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 400,
                        color: 'white',
                        alignSelf: 'center',
                        margin: 'auto',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Refer to GP
                    </Button>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>

        <FormControl sx={{ width: '25ch', marginTop: 5 }}>
          <OutlinedInput
            style={{
              borderRadius: 5,
              height: 35,
              width: 600,
              marginTop: 0,
              border: fieldErrorMedicine['otherNotes'] ? '1px solid red' : '',
            }}
            placeholder="Follow Up Note"
            name={'otherNotes'}
            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
            onChange={setFormValuesMedicine}
          />
        </FormControl>

        <div style={{ marginTop: 30 }}>
          <Button
            variant={'contained'}
            onClick={handleMedicineReview}
            style={{
              height: 30,
              width: 140,
              borderRadius: 50,
              fontSize: 12,
              fontWeight: 400,
              color: 'white',
              alignSelf: 'center',
              margin: 'auto',
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            Review Medicine
          </Button>
        </div>
      </>
    );
  };
  const interventionUpHtml = () => {
    return (
      <>
        <h1 className={'questionair-header-css'}>
          Is this a new medicine? <span style={{ color: fieldErrorMedicine['newMedicine'] ? 'red' : 'green' }}>*</span>
        </h1>
        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={newMedicine}
              name="newMedicine"
              onChange={handleNewMedicine}
            >
              <div>
                <FormControlLabel
                  className="custom-checkbox-design"
                  sx={{ border: newMedicine === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                  value="true"
                  control={
                    <Radio
                      size="small"
                      checkedIcon={<CheckCircle />}
                      icon={<RadioButtonUnchecked />}
                      style={{
                        color: newMedicine === 'true' ? '#236EBA' : '#BCBCBC',
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif', color: newMedicine === 'true' ? '#236EBA' : '#BCBCBC' }}
                    >
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  className="custom-checkbox-design"
                  sx={{ border: newMedicine === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                  value="false"
                  control={
                    <Radio
                      size="small"
                      checkedIcon={<CheckCircle />}
                      icon={<RadioButtonUnchecked />}
                      style={{
                        color: newMedicine === 'false' ? '#236EBA' : '#BCBCBC',
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: 15, fontFamily: 'Roboto, sans-serif', color: newMedicine === 'false' ? '#236EBA' : '#BCBCBC' }}
                    >
                      No
                    </Typography>
                  }
                  style={{ marginLeft: 30 }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        {newMedicine === 'true' && (
          <>
            <h1 className={'questionair-header-css'}>
              Does it have an eligible condition?{' '}
              <span style={{ color: fieldErrorMedicine['is_eligible_condition'] ? 'red' : 'green' }}>*</span>
            </h1>
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={sessionValuesMedicine['is_eligible_condition']}
                  name="is_eligible_condition"
                  onChange={handle_set_is_eligible_condition}
                >
                  <div>
                    <FormControlLabel
                      className="custom-checkbox-design"
                      sx={{ border: sessionValuesMedicine['is_eligible_condition'] === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                      value="true"
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckCircle />}
                          icon={<RadioButtonUnchecked />}
                          style={{
                            color: sessionValuesMedicine['is_eligible_condition'] === 'true' ? '#236EBA' : '#BCBCBC',
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: 'Roboto, sans-serif',
                            color: sessionValuesMedicine['is_eligible_condition'] === 'true' ? '#236EBA' : '#BCBCBC',
                          }}
                        >
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className="custom-checkbox-design"
                      sx={{
                        border: sessionValuesMedicine['is_eligible_condition'] === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC',
                      }}
                      value="false"
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckCircle />}
                          icon={<RadioButtonUnchecked />}
                          style={{
                            color: sessionValuesMedicine['is_eligible_condition'] === 'false' ? '#236EBA' : '#BCBCBC',
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: 'Roboto, sans-serif',
                            color: sessionValuesMedicine['is_eligible_condition'] === 'false' ? '#236EBA' : '#BCBCBC',
                          }}
                        >
                          No
                        </Typography>
                      }
                      style={{ marginLeft: 30 }}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {eligibleCondition === 'true' && (
          <>
            <h1 className={'questionair-header-css'}>
              Eligible Condition <span style={{ color: fieldErrorMedicine['medicineConditions'] ? 'red' : 'green' }}>*</span>
            </h1>

            <FormControl>
              <Select
                id="demo-simple-select"
                name={'medicineConditions'}
                value={selectNewMedicineConditions}
                onChange={handleSelectNewMedicineCondition}
                style={{
                  height: '40px',
                  width: '350px',
                  fontSize: '12px',
                  backgroundColor: 'white',
                  color: '#393939',
                }}
              >
                <MenuItem disabled value={0}>
                  Select Medicine Condition
                </MenuItem>
                {medicineConditionsList.map(elem => {
                  return (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <h1 className={'questionair-header-css'}>
              Is the patient using medicine as prescribed ?{' '}
              <span style={{ color: fieldErrorMedicine['medicineUsedAsPrescribed'] ? 'red' : 'green' }}>*</span>
            </h1>
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={patient_using_medicine_as_prescribed_value}
                  name="medicineUsedAsPrescribed"
                  onChange={handlesetPatient_using_medicine_as_prescribed}
                >
                  <div>
                    <FormControlLabel
                      className="custom-checkbox-design"
                      sx={{ border: patient_using_medicine_as_prescribed_value === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                      value="true"
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckCircle />}
                          icon={<RadioButtonUnchecked />}
                          style={{
                            color: patient_using_medicine_as_prescribed_value === 'true' ? '#236EBA' : '#BCBCBC',
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: 'Roboto, sans-serif',
                            color: patient_using_medicine_as_prescribed_value === 'true' ? '#236EBA' : '#BCBCBC',
                          }}
                        >
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className="custom-checkbox-design"
                      sx={{ border: patient_using_medicine_as_prescribed_value === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                      value="false"
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckCircle />}
                          icon={<RadioButtonUnchecked />}
                          style={{
                            color: patient_using_medicine_as_prescribed_value === 'false' ? '#236EBA' : '#BCBCBC',
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: 'Roboto, sans-serif',
                            color: patient_using_medicine_as_prescribed_value === 'false' ? '#236EBA' : '#BCBCBC',
                          }}
                        >
                          No
                        </Typography>
                      }
                      style={{ marginLeft: 30 }}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            {patient_using_medicine_as_prescribed_value === 'false' ? (
              <div>
                <h1 className={'questionair-header-css'}>
                  Patient reports not using the medicine as prescribed{' '}
                  <span style={{ color: fieldErrorMedicine['sessionMedicineUsedAsPrescribedNoType'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={patient_reports_not_using_the_medicine_as_prescribed}
                      name="sessionMedicineUsedAsPrescribedNoType"
                      style={{ marginTop: 5 }}
                      onChange={handleNotUsedAsPrescribedOption}
                    >
                      {medicineUsedAsPrescribedNoTypeEntities.map((label, key) => (
                        <FormControlLabel
                          key={key}
                          style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                          value={label.id}
                          control={<Radio size="small" />}
                          label={label.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ) : null}
            {!prescriberStoppedMedication && (
              <>
                <h1 className={'questionair-header-css'}>
                  Issues reported by the patient{' '}
                  <span style={{ color: fieldErrorMedicine['patientReportedIssues'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormGroup>
                    {patientReportedIssueTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        control={
                          <Checkbox
                            size="small"
                            name={'patientReportedIssues'}
                            checked={issues_reported_by_the_patient_Value.some(report => report.value.includes(label.name))}
                            onChange={e => {
                              handleIssueToggle('patientReportedIssues', label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                  {issues_reported_by_the_patient_Value.some(report => report.value.includes('Other')) ? (
                    <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                      <OutlinedInput
                        style={{
                          border: fieldErrorMedicine['patientReportedIssuesOther'] ? '1px solid red' : '1px solid lightgray',
                          borderRadius: 5,
                          height: 35,
                          width: 600,
                          marginTop: 0,
                        }}
                        name={'patientReportedIssuesOther'}
                        placeholder="Please Indicate"
                        onChange={setFormValuesMedicine}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                    </FormControl>
                  ) : null}
                </div>

                <h1 className={'questionair-header-css'}>
                  Information Provided <span style={{ color: fieldErrorMedicine['patientProvidedInformation'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormGroup>
                    {patientProvideInformationTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                        control={
                          <Checkbox
                            size="small"
                            name={'patientProvidedInformation'}
                            checked={patient_provided_information_value.some(report => report.value.includes(label.name))}
                            onChange={e => {
                              handleIssueToggle('patientProvidedInformation', label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                </div>

                <h1 className={'questionair-header-css'}>
                  Agreed patient actions <span style={{ color: fieldErrorMedicine['patientAgreedAction'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormGroup>
                    {patientAgreedActionTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            size="small"
                            style={{ color: '' }}
                            value={label.name}
                            name={'patientAgreedAction'}
                            checked={agreedPatientActions.some(report => report.value.includes(label.name))}
                            onChange={e => {
                              handleAgreedPatientActions(label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>

                  {agreedPatientActions.some(report => report.value.includes('Other')) && (
                    <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                      <OutlinedInput
                        style={{
                          border: fieldErrorMedicine['patientAgreedActionOther'] ? '1px solid red' : '1px solid lightgray',
                          borderRadius: 5,
                          height: 35,
                          width: 600,
                          marginTop: 0,
                        }}
                        placeholder="Please Indicate"
                        name={'patientAgreedActionOther'}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                        onChange={setFormValuesMedicine}
                      />
                    </FormControl>
                  )}
                </div>

                <h1 className={'questionair-header-css'}>
                  Agreed pharmacist actions <span style={{ color: fieldErrorMedicine['agreedPharmacistAction'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormGroup>
                    {pharmacistAgreedActionTypeEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            size="small"
                            style={{ borderColor: 'lightgray' }}
                            value={label.name}
                            name={'agreedPharmacistAction'}
                            checked={agreedPharmacistActions.some(report => report.value.includes(label.name))}
                            onChange={e => {
                              handleAgreedPharmacistActions(label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>

                  {agreedPharmacistActions.some(report => report.value.includes('Other')) && (
                    <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                      <OutlinedInput
                        style={{
                          border: fieldErrorMedicine['agreedPharmacistActionOther'] ? '1px solid red' : '1px solid lightgray',
                          borderRadius: 5,
                          height: 35,
                          width: 600,
                          marginTop: 0,
                        }}
                        placeholder="Please Indicate"
                        name={'agreedPharmacistActionOther'}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                        onChange={setFormValuesMedicine}
                      />
                    </FormControl>
                  )}
                </div>

                {agreedPharmacistActions.some(report => report.value.includes('Referred back to the prescriber')) && (
                  <div>
                    <h1 className={'questionair-header-css'}>
                      Referred back to the prescriber{' '}
                      <span style={{ color: fieldErrorMedicine['pharmacistAgreedReferBack'] ? 'red' : 'green' }}>*</span>
                    </h1>
                    <div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name={'pharmacistAgreedReferBack'}
                          value={referred_back_to_the_prescriber}
                          onChange={handle_referred_back_to_the_prescriber}
                          style={{ marginTop: 5 }}
                        >
                          {pharmacistReferBackTypes &&
                            pharmacistReferBackTypes.length > 0 &&
                            pharmacistReferBackTypes.map((label, key) => (
                              <FormControlLabel
                                key={key}
                                style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                                value={label.id}
                                data-value={label.name}
                                control={<Radio size="small" />}
                                label={label.name}
                              />
                            ))}
                        </RadioGroup>
                      </FormControl>
                    </div>

                    {referred_back_to_the_prescriber === '10' && (
                      <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                        <OutlinedInput
                          style={{
                            border: fieldErrorMedicine['pharmacistAgreedReferBackOther'] ? '1px solid red' : '1px solid lightgray',
                            borderRadius: 5,
                            height: 35,
                            width: 600,
                            marginTop: 0,
                          }}
                          placeholder="Please Indicate"
                          name={'pharmacistAgreedReferBackOther'}
                          inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          onChange={setFormValuesMedicine}
                        />
                      </FormControl>
                    )}

                    <div style={{ height: 'max-content', backgroundColor: 'white', marginTop: 20 }}>
                      <h2 className={'questionair-header-css'}>Patient Registered GP Details: </h2>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <OutlinedInput
                            // className="outline"
                            placeholder="GP Practice Name"
                            name="gPPracticeName"
                            value={gpValues ? gpValues['gPPracticeName'] : ''}
                            onChange={setGpFormValues}
                            fullWidth
                            sx={{
                              maxWidth: 'none',
                              border:
                                agreedPharmacistActions.some(report => report.value.includes('Referred back to the prescriber')) &&
                                gpFieldError['gPPracticeName']
                                  ? '1px solid red'
                                  : '',
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4} sx={{ marginTop: '3px' }}>
                          <OutlinedInput
                            // className="outline"
                            placeholder="Address"
                            name="address"
                            fullWidth
                            value={gpValues ? gpValues['address'] : ''}
                            onChange={setGpFormValues}
                            sx={{
                              maxWidth: 'none',
                              border:
                                agreedPharmacistActions.some(report => report.value.includes('Referred back to the prescriber')) &&
                                gpValues['address'] === ''
                                  ? '1px solid red'
                                  : '',
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4} sx={{ marginTop: '3px' }}>
                          <OutlinedInput
                            // className="outline"
                            placeholder="Referral comment"
                            name="referralComment"
                            fullWidth
                            value={gpValues ? gpValues['referralComment'] : ''}
                            onChange={setGpFormValues}
                            sx={{
                              maxWidth: 'none',
                              border:
                                agreedPharmacistActions.some(report => report.value.includes('Referred back to the prescriber')) &&
                                (gpValues['referralComment'] === '' || !gpValues['referralComment'])
                                  ? '1px solid red'
                                  : '',
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton color="inherit" style={{ height: 20 }}>
                                  {/* <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '18px' }} /> */}
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          variant={'contained'}
                          onClick={handleOpenGp}
                          disabled={JSON.parse(localStorage.getItem('interventionReferIds'))?.some(
                            iid => iid === Number(sessionValuesMedicine['prescription_medicine_id'])
                          )}
                          style={{
                            background: JSON.parse(localStorage.getItem('interventionReferIds'))?.some(
                              iid => iid === Number(sessionValuesMedicine['prescription_medicine_id'])
                            )
                              ? 'grey'
                              : '',
                            height: 30,
                            width: 140,
                            borderRadius: 50,
                            fontSize: 12,
                            fontWeight: 400,
                            color: 'white',
                            alignSelf: 'center',
                            margin: 'auto',
                            fontFamily: 'Roboto, sans-serif',
                            marginBottom: 20,
                          }}
                        >
                          Refer to GP
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <h1 className={'questionair-header-css'}>
                  Healthy Living Advice <span style={{ color: fieldErrorMedicine['healthyLivingAdvice'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormGroup>
                    {healthyLivingAdviceEntities.map((label, key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            size="small"
                            style={{ borderColor: 'lightgray' }}
                            value={label.name}
                            name={'healthyLivingAdvice'}
                            checked={selectedAdvice.some(report => report.value.includes(label.name))}
                            onChange={e => {
                              handleHealthyLivingAdvice(label.name, label.id, e);
                            }}
                          />
                        }
                        label={label.name}
                      />
                    ))}
                  </FormGroup>
                  {selectedAdvice.some(report => report.value.includes('Other')) && (
                    <FormControl sx={{ width: '25ch', marginTop: 2 }}>
                      <OutlinedInput
                        style={{
                          border: fieldErrorMedicine['healthyLivingAdviceOther'] ? '1px solid red' : '1px solid lightgray',
                          borderRadius: 5,
                          height: 35,
                          width: 600,
                          marginTop: 0,
                        }}
                        placeholder="Please Indicate"
                        name={'healthyLivingAdviceOther'}
                        inputProps={{ style: { fontSize: 13 } }}
                        onChange={setFormValuesMedicine}
                      />
                    </FormControl>
                  )}
                </div>
                <Divider style={{ backgroundColor: 'lightgray', height: 2, width: 600, marginTop: 30, marginBottom: 30 }} />
                <TextareaAutosize
                  className="outline"
                  placeholder="Please Type Intervention Note."
                  name="interventionNote"
                  minRows={5}
                  maxRows={20}
                  // onKeyUp={handleOpen}
                  value={sessionValuesMedicine['interventionNote']}
                  onChange={setFormValuesMedicine}
                  style={{
                    // height: 35,
                    borderRadius: 5,
                    width: 600,
                    border: fieldErrorMedicine['interventionNote'] ? '2px solid #ff6d6d' : '2px solid lightgray',
                    fontSize: 13,
                    // fontFamily: 'Roboto, sans-serif',
                  }}
                />

                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Review Medicine
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Grid className="text2" lg={12} container justifyContent="center" style={{ marginTop: 15 }}>
      <Grid
        container
        lg={10}
        justifyContent="start"
        style={{ flexDirection: 'row', backgroundColor: 'white', marginLeft: 10, paddingBottom: '13%' }}
      >
        <Grid lg={8} container sx={{ flexDirection: 'column', marginLeft: 0, marginTop: 10, justifyContent: 'center' }}>
          <Button
            variant={'contained'}
            style={{
              height: 30,
              width: 140,
              borderRadius: 50,
              fontSize: 12,
              fontWeight: 400,
              color: 'white',
              alignSelf: 'center',
              margin: 'auto',
              fontFamily: 'Roboto, sans-serif',
            }}
            onClick={() => setIsModalOpen(true)}
          >
            Edit Patient
          </Button>
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)} // onClose instead of onRequestClose
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh', // Ensures the modal takes up full height of the screen
              position: 'relative', // Set position to relative to position the button inside the modal
            }}
          >
            <div style={{ position: 'relative', width: '65%', height: '100%' }}>
              <Button
                onClick={() => setIsModalOpen(false)} // Close the modal when clicked
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  zIndex: 10, // Ensure button is on top of other elements
                }}
              >
                Close
              </Button>
              <BlankSheetFormCompAdmin obj={sessionEntity.id} />
            </div>
          </Modal>

          <div style={{ marginLeft: 70, justifyContent: 'start', marginBottom: 100 }}>
            <span
              className={'primary-text-color'}
              style={{
                fontSize: 20,
                fontWeight: 700,
                marginTop: 0,
                marginLeft: 0,
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              Medicine to be Reviewed
            </span>
            <Divider style={{ backgroundColor: 'white', height: 2, width: 600 }} />
            <Divider style={{ backgroundColor: 'white', height: 2, width: 2, marginTop: 50 }} />
            <div style={{ backgroundColor: '#F9F9F9', height: 'auto', width: '85%', marginBottom: '30px' }}>
              <div style={{ backgroundColor: '', padding: '12px' }}>
                {sessionEntity.prevSessionType && sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div></div>
                    <Button
                      variant={'contained'}
                      style={{
                        height: 30,
                        width: 140,
                        borderRadius: 50,
                        fontSize: 12,
                        fontWeight: 400,
                        color: 'white',
                        alignSelf: 'center',
                        margin: 'auto',
                        fontFamily: 'Roboto, sans-serif',
                        marginRight: '20px',
                      }}
                      onClick={handleClickOpenCallBack}
                    >
                      Call back ?
                    </Button>
                  </div>
                )}

                <div style={{ marginTop: 20 }}>
                  {currentSessionDetail.medicines &&
                    currentSessionDetail.medicines.length > 0 &&
                    currentSessionDetail.medicines.map((section, key) => (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          // height: 50,
                          backgroundColor: 'white',
                          justifyContent: 'space-between',
                          alignItems: 'center', // Aligns content vertically
                          padding: '0 10px', // Adds spacing for better alignment
                          marginTop: 3,
                        }}
                      >
                        <h1
                          className={'primary-text-color'}
                          style={{
                            fontSize: 13,
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: 'bold',
                            maxWidth: '40%',
                          }}
                        >
                          {section.medicine.name}
                        </h1>

                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                          <button
                            disabled={
                              sessionEntity['prevSessionType'] &&
                              section.sessionType !== null &&
                              sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                              section['review'] === true
                            }
                            style={{
                              // height: 24,
                              width: 'auto',
                              textAlign: 'center',
                              marginTop: 10,
                              marginBottom: 10,
                              marginRight: 10,
                              borderRadius: 100,
                              backgroundColor:
                                sessionEntity['prevSessionType'] &&
                                section.sessionType !== null &&
                                sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                section['review'] === true
                                  ? 'lightgrey'
                                  : buttonClicked[section.medicine.id]
                                  ? 'rgba(87, 220, 59)'
                                  : 'transparent',
                              color:
                                sessionEntity['prevSessionType'] &&
                                section.sessionType !== null &&
                                sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                section['review'] === true
                                  ? 'white'
                                  : buttonClicked[section.medicine.id]
                                  ? 'white'
                                  : '#69BE33',
                              fontSize: 13,
                              fontFamily: 'Roboto, sans-serif',
                              border: `1px solid ${buttonClicked[key] ? 'rgba(87, 220, 59)' : ''}`,
                            }}
                            data-medicine-name={section.medicine.name}
                            onClick={e => handleButtonClick(section.medicine.id, section.id, section.medicine['medicineConditions'], e)}
                          >
                            <Typography style={{ fontSize: 13, paddingLeft: 10, paddingRight: 10 }}>Start Review</Typography>
                          </button>
                          {sessionEntity.prevSessionType && sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
                            <button
                              disabled={
                                sessionEntity['prevSessionType'] &&
                                section.sessionType !== null &&
                                sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                section['review'] === true
                              }
                              style={{
                                // height: 24,
                                width: 'auto',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                marginRight: 10,
                                borderRadius: 100,
                                backgroundColor: 'transparent',
                                color:
                                  sessionEntity['prevSessionType'] &&
                                  section.sessionType !== null &&
                                  sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                  section['review'] === true
                                    ? 'lightgrey'
                                    : 'rgb(255,7,7)',
                                fontSize: 13,
                                fontFamily: 'Roboto, sans-serif',
                                border: `1px solid ${buttonClickedNotNew[key] ? 'rgb(255,7,7)' : ''}`,
                              }}
                              data-medicine-name={section.medicine.name}
                              onClick={e =>
                                handleButtonClickNotNew(section.medicine.id, section.id, section.medicine['medicineConditions'], e)
                              }
                            >
                              <Typography style={{ fontSize: 13, paddingLeft: 10, paddingRight: 10 }}>Not New Medicine</Typography>
                            </button>
                          )}
                          {sessionEntity.prevSessionType && sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
                            <button
                              disabled={
                                sessionEntity['prevSessionType'] &&
                                section.sessionType !== null &&
                                sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                section['review'] === true
                              }
                              style={{
                                // height: 24,
                                width: 'auto',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                marginRight: 10,
                                borderRadius: 100,
                                backgroundColor: 'transparent',
                                color:
                                  sessionEntity['prevSessionType'] &&
                                  section.sessionType !== null &&
                                  sessionEntity['prevSessionType']?.id === section?.sessionType?.id &&
                                  section['review'] === true
                                    ? 'lightgrey'
                                    : 'rgb(255,7,7)',
                                fontSize: 13,
                                fontFamily: 'Roboto, sans-serif',
                                border: `1px solid ${buttonClickedNotEligible[key] ? 'rgb(255,7,7)' : ''}`,
                              }}
                              data-medicine-name={section.medicine.name}
                              onClick={e =>
                                handleButtonClickNotEligible(section.medicine.id, section.id, section.medicine['medicineConditions'], e)
                              }
                            >
                              <Typography style={{ fontSize: 13, paddingLeft: 10, paddingRight: 10 }}>Not Eligible Condition</Typography>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {sessionEntity.prevSessionType && sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
              <>
                <h1 className={'questionair-header-css'}>
                  NSCR Access <span style={{ color: 'green' }}>*</span>
                </h1>
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={scrAccess}
                      onChange={handleScrAccess}
                      name="scrAccess"
                    >
                      <div>
                        {/* Yes option */}
                        <FormControlLabel
                          className="custom-checkbox-design"
                          sx={{ border: scrAccess === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                          value="true"
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckCircle />}
                              icon={<RadioButtonUnchecked />}
                              style={{
                                color: scrAccess === 'true' ? '#236EBA' : '#BCBCBC',
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontFamily: 'Roboto, sans-serif',
                                color: scrAccess === 'true' ? '#236EBA' : '#BCBCBC',
                              }}
                            >
                              Yes
                            </Typography>
                          }
                        />

                        {/* No option with margin for spacing */}
                        <FormControlLabel
                          className="custom-checkbox-design"
                          sx={{ border: scrAccess === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                          value="false"
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckCircle />}
                              icon={<RadioButtonUnchecked />}
                              style={{
                                color: scrAccess === 'false' ? '#236EBA' : '#BCBCBC',
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: 15,
                                color: scrAccess === 'false' ? '#236EBA' : '#BCBCBC',
                              }}
                            >
                              No
                            </Typography>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <h1 className={'questionair-header-css'}>
                  Intervention delivered <span style={{ color: fieldError['previouslyDelivered'] ? 'red' : 'green' }}>*</span>
                </h1>
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedOptionIntervention}
                      onChange={handleInterventionDeliveredOptionChange}
                      name="previouslyDelivered"
                    >
                      <div>
                        <FormControlLabel
                          className="custom-checkbox-design"
                          sx={{ border: selectedOptionIntervention === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                          value={'true'}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckCircle />}
                              icon={<RadioButtonUnchecked />}
                              style={{
                                color: selectedOptionIntervention === 'true' ? '#236EBA' : '#BCBCBC',
                              }}
                            />
                          }
                          label={
                            <Typography style={{ color: selectedOptionIntervention === 'true' ? '#236EBA' : '#BCBCBC' }}>Yes</Typography>
                          }
                        />
                        <FormControlLabel
                          className="custom-checkbox-design"
                          sx={{ border: selectedOptionIntervention === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                          value={'false'}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckCircle />}
                              icon={<RadioButtonUnchecked />}
                              style={{
                                color: selectedOptionIntervention === 'false' ? '#236EBA' : '#BCBCBC',
                              }}
                            />
                          }
                          label={
                            <Typography style={{ color: selectedOptionIntervention === 'false' ? '#236EBA' : '#BCBCBC' }}>No</Typography>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                {selectedOptionIntervention === 'false' && (
                  <div>
                    <h1
                      style={{
                        color: '#246EBA',
                        fontSize: 17,
                        marginTop: 30,
                        marginLeft: 0,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Reason For Not Completing{' '}
                      <span style={{ color: fieldError['sessionPreviouslyDeliveredNoType'] ? 'red' : 'green' }}>*</span>
                    </h1>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="sessionPreviouslyDeliveredNoType"
                        value={selectedOption}
                        onChange={handleOptionChangeReason}
                        style={{ marginTop: 5, fontFamily: 'Roboto, sans-serif' }}
                      >
                        {previouslyDeliveredNoTypeEntities.map((label, key) => (
                          <FormControlLabel
                            key={key}
                            style={{ fontWeight: 700, fontSize: 13 }}
                            value={label.id}
                            control={<Radio size="small" />}
                            label={label.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <Divider style={{ backgroundColor: 'white', height: 2, width: 600, marginBottom: 20, marginTop: 10 }} />

                    {selectedOption === '6' && (
                      <FormControl sx={{ width: '25ch', marginTop: 1 }}>
                        <OutlinedInput
                          type={'text'}
                          style={{
                            border: fieldError['sessionPreviouslyDeliveredOtherReason'] ? '1px solid red' : '1px solid lightgray',
                            borderRadius: 5,
                            height: 35,
                            width: 600,
                            marginTop: 0,
                          }}
                          placeholder="Please Indicate"
                          onChange={setFormValues}
                          name={'sessionPreviouslyDeliveredOtherReason'}
                          value={sessionValues['sessionPreviouslyDeliveredOtherReason']}
                          inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                        />
                      </FormControl>
                    )}
                  </div>
                )}
              </>
            )}
            <h1 className={'questionair-header-css'}>
              {sessionEntity?.prevSessionType?.name === 'intervention' ? 'Intervention Call Date' : 'Follow-up Call Date'}
            </h1>

            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'start' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format={'DD/MM/YYYY'}
                    value={dayjs()}
                    disabled={true}
                    className="clock"
                    // onChange={newValue => handleConsultationDetail(newValue)}
                    sx={{
                      width: 220,
                      marginTop: 0,
                      marginLeft: 0,
                      border: fieldError['lastModifiedDate'] ? '1px solid red' : '',
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div
                style={{
                  marginTop: 2,
                  borderRadius: 3,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className=""
                    disabled={true}
                    value={dayjs()}
                    // onChange={e => {
                    //   handleConsultationDetailTime(e);
                    // }}
                    sx={{
                      width: 150,

                      marginLeft: 2,
                      border: fieldError['lastModifiedDateTime'] ? '1px solid red' : '',
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <h1 className={'questionair-header-css'}>
              Method of Consultation <span style={{ color: fieldError['sessionConsultationMethod'] ? 'red' : 'green' }}>*</span>
            </h1>
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={methodofConsultation}
                  onChange={handlemethodofConsultation}
                  name="sessionConsultationMethod"
                >
                  <div>
                    {consultationMethodEntities.map((value, key) => (
                      <FormControlLabel
                        key={key}
                        value={value.id}
                        className="custom-checkbox-design"
                        sx={{
                          border: Number(methodofConsultation) === value.id ? '1px solid #236EBA' : '1px solid #BCBCBC',
                          width: '150px',
                        }}
                        control={
                          <Radio
                            size="small"
                            checkedIcon={<CheckCircle />}
                            icon={<RadioButtonUnchecked />}
                            style={{
                              color: Number(methodofConsultation) === value.id ? '#236EBA' : '#BCBCBC',
                            }}
                          />
                        }
                        label={
                          <Typography style={{ color: Number(methodofConsultation) === value.id ? '#236EBA' : '#BCBCBC' }}>
                            {value.name === 'face_to_face'
                              ? 'Face to face'
                              : value.name === 'telephone'
                              ? 'Telephone'
                              : value.name === 'home'
                              ? 'Home'
                              : ''}
                          </Typography>
                        }
                      />
                    ))}
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            {sessionEntity?.prevSessionType?.name === 'intervention' ? (
              <>
                <h1 className={'questionair-header-css'}>
                  What is the ethnicity of the patient? <span style={{ color: fieldError['ethnicityOfPatient'] ? 'red' : 'green' }}>*</span>
                </h1>

                <div>
                  <FormControl>
                    <Select
                      labelId="ethnic-group-select-label"
                      id="ethnic-group-select"
                      name="ethnicityOfPatient"
                      value={selectedOptionEthnic}
                      onChange={handleChangeEthnic}
                      displayEmpty
                      renderValue={selected => {
                        if (selected === '') {
                          return <span style={{ color: '#393939', fontSize: '13px' }}>Select Ethnicity of Patient</span>;
                        }
                        return selected;
                      }}
                      style={{
                        height: '40px',
                        width: '350px',
                        fontSize: '13px',
                        backgroundColor: 'white',
                        color: '#393939',
                      }}
                    >
                      <MenuItem value="">
                        <span style={{ fontSize: '13px' }}>Select Ethnicity of Patient</span>
                      </MenuItem>
                      {ethnicGroups.map(group => [
                        <ListSubheader key={group.label} style={{ fontWeight: 'bold' }}>
                          {group.label}
                        </ListSubheader>,
                        ...group.options.map(option => (
                          <MenuItem key={option.key} value={option.value} style={{ fontSize: '12px', marginLeft: '8px' }}>
                            {option.value}
                          </MenuItem>
                        )),
                      ])}
                    </Select>
                    {showTextFieldEthnic && (
                      <OutlinedInput
                        style={{
                          border: fieldError['ethnicityOfPatientOther'] ? '1px solid red' : '1px solid lightgray',
                          borderRadius: 5,
                          height: 40,
                          marginTop: 2,
                        }}
                        name={'ethnicityOfPatientOther'}
                        placeholder="Please specify"
                        onChange={handleChangeEthnicOther}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                    )}
                  </FormControl>
                </div>
              </>
            ) : null}

            {showMedicineRelatedContent && (
              <div>
                {sessionEntity?.prevSessionType?.id === NMS_TYPES.FOLLOW_UP && (
                  <Box
                    sx={{
                      backgroundColor: 'rgb(245, 246, 250)',
                      padding: 2,
                      borderRadius: 1,
                      maxWidth: '60%',
                      marginTop: '20px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        color: 'rgb(36, 110, 186)',
                        fontSize: '17px',
                        marginLeft: '0px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Intervention Note
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>
                      {sessionEntity?.prescription?.prescriptionMedicines
                        ?.map(med => med.interventionNote)
                        .filter(note => note) // Filter out any undefined or null values
                        .join(' ')}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: 'rgb(36, 110, 186)',
                        fontSize: '17px',
                        marginLeft: '0px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Intervention Date
                    </Typography>
                    <Box>
                      <Typography variant="body1" sx={{ fontSize: 15, fontFamily: 'Roboto, sans-serif' }}>
                        {moment(sessionEntity?.actualInterventionDate).format('DD-MM-YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <>
                  {sessionEntity.prevSessionType &&
                    sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP &&
                    selectedOptionIntervention === 'true' &&
                    interventionUpHtml()}
                  {sessionEntity.prevSessionType && sessionEntity.prevSessionType.id === NMS_TYPES.FOLLOW_UP && followUpHtml()}
                </>
              </div>
            )}

            {selectedOptionIntervention === 'false' && (
              <div style={{ marginTop: 30 }}>
                <Button
                  variant={'contained'}
                  onClick={handleInterventionNotDeliveredSession}
                  style={{
                    height: 30,
                    width: 140,
                    borderRadius: 50,
                    fontSize: 12,
                    fontWeight: 400,
                    color: 'white',
                    alignSelf: 'center',
                    margin: 'auto',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Close NMS
                </Button>
              </div>
            )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              (newMedicine === 'false' || eligibleCondition === 'false') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 1 &&
              newMedicineReviewed >= 0 &&
              unReviewedMedicine > 1 && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Review Medicine
                  </Button>
                </div>
              )}

            {/*{selectedOptionIntervention === 'true' &&*/}
            {/*  showMedicineRelatedContent &&*/}
            {/*  (newMedicine === 'false' || eligibleCondition === 'false') &&*/}
            {/*  currentSessionDetail.medicines &&*/}
            {/*  currentSessionDetail.medicines.length > 1 &&*/}
            {/*  newMedicineReviewed >= 1 &&*/}
            {/*  unReviewedMedicine > 0 && (*/}
            {/*    <div style={{ marginTop: 30 }}>*/}
            {/*      <Button*/}
            {/*        variant={'contained'}*/}
            {/*        onClick={handleMedicineReview}*/}
            {/*        style={{*/}
            {/*          height: 30,*/}
            {/*          width: 140,*/}
            {/*          borderRadius: 50,*/}
            {/*          fontSize: 12,*/}
            {/*          fontWeight: 400,*/}
            {/*          color: 'white',*/}
            {/*          alignSelf: 'center',*/}
            {/*          margin: 'auto',*/}
            {/*          fontFamily: 'Roboto, sans-serif',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        Review Medicine 2*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  )}*/}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              (newMedicine === 'false' || eligibleCondition === 'false') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 0 &&
              unReviewedMedicine === 1 &&
              currentSessionDetail?.medicines
                ?.filter(medicine => medicine.review === true)
                ?.every(innerMedicine => innerMedicine.isNewMedicine === false || innerMedicine.isEligibleCondition === false) && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleInEligibleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              (newMedicine === 'false' || eligibleCondition === 'false') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 0 &&
              unReviewedMedicine === 1 &&
              currentSessionDetail?.medicines
                ?.filter(medicine => medicine.review === true)
                ?.some(innerMedicine => innerMedicine.isNewMedicine === true && innerMedicine.isEligibleCondition === true) && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Review Medicine
                  </Button>
                </div>
              )}

            {/*handle prescriber stopped new medicine action buttons*/}
            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 1 &&
              unReviewedMedicine === 1 &&
              newMedicineReviewed >= 0 &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType === null &&
              currentSessionDetail?.medicines
                ?.filter(medicine => medicine.review === true)
                ?.some(innerMedicine => innerMedicine.isNewMedicine === true && innerMedicine.isEligibleCondition === true) && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReviewPrescriberStoppedMedication}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 1 &&
              unReviewedMedicine === 1 &&
              newMedicineReviewed >= 0 &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType === null &&
              currentSessionDetail?.medicines
                ?.filter(medicine => medicine.review === true)
                ?.some(innerMedicine => innerMedicine.isNewMedicine === false || innerMedicine.isEligibleCondition === false) && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleInEligibleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length === 1 &&
              unReviewedMedicine === 1 && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleInEligibleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 0 &&
              unReviewedMedicine === 1 &&
              newMedicineReviewed >= 0 &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType !== null &&
              (sessionEntity?.sessionMedicineUsedAsPrescribedNoType['name'] === 'Prescriber has stopped new medicine' ||
                sessionEntity?.sessionMedicineUsedAsPrescribedNoType['name'] === 'Patient has not started using the medicine') && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleInEligibleMedicineReview}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 0 &&
              unReviewedMedicine === 1 &&
              newMedicineReviewed >= 0 &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType !== null &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType['name'] !== 'Prescriber has stopped new medicine' &&
              sessionEntity?.sessionMedicineUsedAsPrescribedNoType['name'] !== 'Patient has not started using the medicine' && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReviewPrescriberStoppedMedication}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}

            {selectedOptionIntervention === 'true' &&
              showMedicineRelatedContent &&
              prescriberStoppedMedication &&
              (newMedicine === 'true' || eligibleCondition === 'true') &&
              currentSessionDetail.medicines &&
              currentSessionDetail.medicines.length > 1 &&
              newMedicineReviewed === 0 &&
              unReviewedMedicine > 1 && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant={'contained'}
                    onClick={handleMedicineReviewPrescriberStoppedMedication}
                    style={{
                      height: 30,
                      width: 140,
                      borderRadius: 50,
                      fontSize: 12,
                      fontWeight: 400,
                      color: 'white',
                      alignSelf: 'center',
                      margin: 'auto',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Review Medicine
                  </Button>
                </div>
              )}

            {showFollowUpDetails && (
              <div>
                {showFollowUpDetails && sessionEntity.prevSessionType && sessionEntity.prevSessionType.id !== NMS_TYPES.FOLLOW_UP && (
                  <>
                    <h1 className={'questionair-header-css'}>
                      Follow Up <span style={{ color: fieldErrorFollowUp['followUp'] ? 'red' : 'green' }}>*</span>
                    </h1>
                    <div>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={selectedFollowUp}
                          onChange={handleFollowUpOptionChange}
                          name="followUp"
                        >
                          <div>
                            <FormControlLabel
                              className="custom-checkbox-design"
                              sx={{ border: selectedFollowUp === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                              value={'true'}
                              control={
                                <Radio
                                  size="small"
                                  checkedIcon={<CheckCircle />}
                                  icon={<RadioButtonUnchecked />}
                                  style={{
                                    color: selectedFollowUp === 'true' ? '#236EBA' : '#BCBCBC',
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    fontFamily: 'Roboto, sans-serif',
                                    color: selectedFollowUp === 'true' ? '#236EBA' : '#BCBCBC',
                                  }}
                                >
                                  Yes
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              className="custom-checkbox-design"
                              sx={{ border: selectedFollowUp === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                              value={'false'}
                              control={
                                <Radio
                                  size="small"
                                  checkedIcon={<CheckCircle />}
                                  icon={<RadioButtonUnchecked />}
                                  style={{
                                    color: selectedFollowUp === 'false' ? '#236EBA' : '#BCBCBC',
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    fontFamily: 'Roboto, sans-serif',
                                    color: selectedFollowUp === 'false' ? '#236EBA' : '#BCBCBC',
                                  }}
                                >
                                  No
                                </Typography>
                              }
                              style={{ marginLeft: 30 }}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {selectedFollowUp === 'true' ? (
                      <>
                        {' '}
                        <h1 className={'questionair-header-css'}>Choose Date for Follow Up</h1>
                        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'start' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={dateTwo}
                                minDate={dayjs().add(10, 'days')}
                                maxDate={dayjs().add(14, 'days')}
                                className="clock"
                                format={'DD/MM/YYYY'}
                                onChange={newValue => handleFollowUpDate(newValue)}
                                sx={{
                                  width: 220,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  border: fieldErrorFollowUp['nextSession'] ? '1px solid red' : '',
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h1 className={'questionair-header-css'}>
                          Follow Up declined by patient?{' '}
                          <span style={{ color: fieldErrorFollowUp['followUpDeclined'] ? 'red' : 'green' }}>*</span>
                        </h1>
                        <div>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={selectedFollowUpDeclined}
                              onChange={handleFollowUpDeclined}
                              name="followUpDeclined"
                            >
                              <div>
                                <FormControlLabel
                                  className="custom-checkbox-design"
                                  sx={{ border: selectedFollowUpDeclined === 'true' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                                  value={'true'}
                                  control={
                                    <Radio
                                      size="small"
                                      checkedIcon={<CheckCircle />}
                                      icon={<RadioButtonUnchecked />}
                                      style={{
                                        color: selectedFollowUpDeclined === 'true' ? '#236EBA' : '#BCBCBC',
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      style={{
                                        fontSize: 15,
                                        fontFamily: 'Roboto, sans-serif',
                                        color: selectedFollowUpDeclined === 'true' ? '#236EBA' : '#BCBCBC',
                                      }}
                                    >
                                      Yes
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  className="custom-checkbox-design"
                                  sx={{ border: selectedFollowUpDeclined === 'false' ? '1px solid #236EBA' : '1px solid #BCBCBC' }}
                                  value={'false'}
                                  control={
                                    <Radio
                                      size="small"
                                      checkedIcon={<CheckCircle />}
                                      icon={<RadioButtonUnchecked />}
                                      style={{
                                        color: selectedFollowUpDeclined === 'false' ? '#236EBA' : '#BCBCBC',
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      style={{
                                        fontSize: 15,
                                        fontFamily: 'Roboto, sans-serif',
                                        color: selectedFollowUpDeclined === 'false' ? '#236EBA' : '#BCBCBC',
                                      }}
                                    >
                                      No
                                    </Typography>
                                  }
                                  style={{ marginLeft: 30 }}
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {declinedPromptShow && (
                          <>
                            <h1 className={'questionair-header-css'}>Reason?</h1>
                            <TextField
                              style={{
                                marginTop: '1%',
                                marginBottom: '3%',
                                border: fieldErrorFollowUp['declinedReason'] ? '1px solid red' : '',
                                fontSize: 15,
                                fontFamily: 'Roboto, sans-serif',
                                height: 39,
                                width: 600,
                              }}
                              className="outline"
                              placeholder="Type reason for follow up decline."
                              name="declinedReason"
                              fullWidth
                              value={sessionValues['declinedReason']}
                              onChange={setFormValues}
                              helperText="Example: include going on holiday, upcoming appointment with healthcare professional, language barriers, no longer using medicine, using medicine when required."
                              FormHelperTextProps={{
                                style: { color: 'grey', fontSize: 12, fontFamily: 'Roboto, sans-serif', marginLeft: 0 },
                              }} // This styles the helper text
                            />
                          </>
                        )}
                        {selectedFollowUpDeclined === 'false' && (
                          <div>
                            <h1 className={'questionair-header-css'}>
                              Reason? <span style={{ color: fieldErrorFollowUp['notDeclinedByPatient'] ? 'red' : 'green' }}>*</span>
                            </h1>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={sessionValues ? sessionValues['notDeclinedByPatient'] : ''}
                                name="notDeclinedByPatient"
                                onChange={handleNotDeclinedByPatient}
                              >
                                <FormControlLabel
                                  style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                                  value={'referred_to_gp'}
                                  control={<Radio size="small" />}
                                  label={'Referred to GP'}
                                />
                                {/*<FormControlLabel*/}
                                {/*  style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}*/}
                                {/*  value={'medication_stopped_no_follow_up'}*/}
                                {/*  control={<Radio size="small" />}*/}
                                {/*  label={'Medication stopped by prescriber - follow up not required'}*/}
                                {/*/>*/}
                                <FormControlLabel
                                  style={{ fontWeight: 700, fontSize: 13, fontFamily: 'Roboto, sans-serif' }}
                                  value={'medication_changed'}
                                  control={<Radio size="small" />}
                                  label={'Medication changed'}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                <h1 className={'questionair-header-css'}>Patient Contact Details</h1>

                <FormControl disabled={true} sx={{ width: '25ch', marginTop: 1 }}>
                  <OutlinedInput
                    style={{ border: '1px solid lightgray', borderRadius: 5, height: 35, width: 387 }}
                    placeholder="Phone No."
                    value={currentSessionDetail.patientDetail && currentSessionDetail.patientDetail['telephone']}
                    onChange={handlePhoneNumberChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton color="inherit" style={{ height: 20 }}>
                          <img src={'../../../../content/assets/phone-call-alt-1-svgrepo-com.png'} alt="logo" style={{ height: '15px' }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                  />
                </FormControl>

                <h1 className={'questionair-header-css'}>Delivered By</h1>

                <div style={{ display: 'flex', marginTop: 0, width: 400 }}>
                  <FormControl disabled={true} sx={{ width: '25ch', marginTop: 1 }}>
                    <OutlinedInput
                      style={{ border: '1px solid lightgray', borderRadius: 5, height: 35, width: 180 }}
                      placeholder="First Name"
                      value={currentUser && currentUser['firstName']}
                      onChange={handleFirstNameChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img
                              src={'../../../../content/assets/Page-1.png'} // Use the image URL from the array
                              alt="logo"
                              style={{ height: '15px' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </FormControl>

                  <FormControl disabled={true} sx={{ width: '25ch', marginTop: 1 }}>
                    <OutlinedInput
                      style={{ border: '1px solid lightgray', borderRadius: 5, height: 35, width: 180 }}
                      placeholder="GPhC No."
                      value={currentAppUser && currentAppUser['gphcNumber']}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton color="inherit" style={{ height: 20 }}>
                            <img
                              src={'../../../../content/assets/Page-1.png'} // Use the image URL from the array
                              alt="logo"
                              style={{ height: '15px' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                    />
                  </FormControl>
                </div>
                <div style={{ display: 'flex', marginTop: 50, width: 350 }}>
                  <IconButton
                    style={{
                      backgroundColor: '#EFEFEF',
                      marginLeft: 0,
                      height: 40,
                      width: 170,
                      border: '1px solid lightgray',
                      padding: '10px',
                      display: 'flex',
                      borderRadius: 3,
                      justifyContent: 'space-around',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s', // Adding transition for smooth hover effect
                    }}
                    onClick={handleDashboardRedirect}
                  >
                    <h1
                      style={{
                        fontSize: 15,
                        marginTop: 8,
                        marginLeft: 0,
                        fontWeight: 400,
                        alignSelf: 'center',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Cancel
                    </h1>
                  </IconButton>
                  <IconButton
                    style={{
                      backgroundColor: '#5EBA24',
                      marginLeft: 10,
                      height: 40,
                      width: 170,
                      border: '1px solid lightgray',
                      padding: '10px',
                      display: 'flex',
                      borderRadius: 3,
                      justifyContent: 'space-around',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                    onClick={handleSessionSubmit}
                  >
                    <div style={{ height: 28, width: 210, padding: '7px', display: 'flex', borderRadius: 3 }}>
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          marginLeft: 30,
                          fontWeight: 400,
                          alignSelf: 'center',
                          color: 'white',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Complete
                      </h1>
                      <img
                        src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px', marginLeft: 6 }}
                      />
                    </div>
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </Grid>

        <Grid
          lg={3}
          container
          justifyContent="center"
          sx={{
            flexDirection: 'row',
            marginLeft: 0,
            marginTop: 'auto',
            backgroundColor: '#F9F9F9',
            height: 'auto',
            position: 'fixed',
            right: '15%',
            width: 'inherit',
          }}
        >
          <Grid
            container
            sx={{
              flexDirection: 'column',
              marginLeft: 6,
              marginRight: 6,
              marginTop: 0,
              backgroundColor: '#F9F9F9',
            }}
          >
            {medicineName && (
              <Box
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: 5,
                  marginTop: 35,
                  textAlign: 'center',
                }}
              >
                <Typography
                  className={'primary-text-color'}
                  style={{
                    fontSize: 13,
                    fontWeight: 'bold',
                  }}
                >
                  {medicineName}
                </Typography>
              </Box>
            )}

            <Box
              style={{
                width: '100%',
                height: 35,
                backgroundColor: '#246EBA',
                borderRadius: 5,
                marginTop: 35,
                textAlign: 'center',
              }}
            >
              <Typography
                style={{
                  color: '#FFFFFF',
                  fontSize: 15,
                  marginTop: 8,
                  fontWeight: 400,
                }}
              >
                Pharmacist Name
              </Typography>

              <Autocomplete
                disablePortal
                id="pharmacist-name"
                options={allServicesPharmacist} // Use allServicesPharmacist as the options
                getOptionLabel={(option: Pharmacist) => option.firstName} // Type the option as Pharmacist
                onChange={handleSelectPharmacist} // Handle selection change
                renderInput={params => (
                  <TextField {...params} label="Select Pharmacist to assign" variant="outlined" style={{ marginTop: 15 }} />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={callBackCommentPopUp}
        TransitionComponent={Transition}
        onClose={handleCloseCommentPopUp}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ color: '#236EBA' }}>{'Call back comment.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{sessionEntity?.callBackComment}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCommentPopUp}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            ok
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={notNewPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotNew}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Are you sure it's not a new medicine ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseNotNew}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
            No
          </Button>
          {currentSessionDetail?.medicines?.length > 1 && newMedicineReviewed === 0 && unReviewedMedicine > 1 && (
            <Button
              onClick={handleMedicineReview}
              autoFocus
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              Yes
              <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
            </Button>
          )}

          {currentSessionDetail?.medicines?.length > 0 &&
            unReviewedMedicine === 1 &&
            currentSessionDetail?.medicines
              ?.filter(medicine => medicine.review === true)
              ?.every(innerMedicine => innerMedicine.isNewMedicine === false || innerMedicine.isEligibleCondition === false) && (
              <Button
                onClick={() => handleInEligibleMedicineReview()}
                autoFocus
                variant="contained"
                sx={{
                  backgroundColor: '#45C12B',
                  borderRadius: '2px', // make the button a perfect circle
                  padding: '10px 20px',
                  fontSize: 12,
                  width: 100, // set the width of the button
                  height: 40, // set the height of the button
                  position: 'relative',
                }}
              >
                Yes
                <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
              </Button>
            )}
          {currentSessionDetail?.medicines?.length > 0 &&
            unReviewedMedicine === 1 &&
            currentSessionDetail?.medicines
              ?.filter(medicine => medicine.review === true)
              ?.some(innerMedicine => innerMedicine.isNewMedicine === true && innerMedicine.isEligibleCondition === true) && (
              <Button
                onClick={() => handleMedicineReview()}
                autoFocus
                variant="contained"
                sx={{
                  backgroundColor: '#45C12B',
                  borderRadius: '2px', // make the button a perfect circle
                  padding: '10px 20px',
                  fontSize: 12,
                  width: 100, // set the width of the button
                  height: 40, // set the height of the button
                  position: 'relative',
                }}
              >
                Yes
                <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
              </Button>
            )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={notEligiblePopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotEligible}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Are you sure it has an ineligible condition ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseNotEligible}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
            No
          </Button>
          {currentSessionDetail?.medicines?.length > 1 && newMedicineReviewed === 0 && unReviewedMedicine > 1 && (
            <Button
              onClick={handleMedicineReview}
              autoFocus
              variant="contained"
              sx={{
                backgroundColor: '#45C12B',
                borderRadius: '2px', // make the button a perfect circle
                padding: '10px 20px',
                fontSize: 12,
                width: 100, // set the width of the button
                height: 40, // set the height of the button
                position: 'relative',
              }}
            >
              Yes
              <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
            </Button>
          )}
          {currentSessionDetail?.medicines?.length > 0 &&
            unReviewedMedicine === 1 &&
            currentSessionDetail?.medicines
              ?.filter(medicine => medicine.review === true)
              ?.every(innerMedicine => innerMedicine.isNewMedicine === false || innerMedicine.isEligibleCondition === false) && (
              <Button
                onClick={() => handleInEligibleMedicineReview()}
                autoFocus
                variant="contained"
                sx={{
                  backgroundColor: '#45C12B',
                  borderRadius: '2px', // make the button a perfect circle
                  padding: '10px 20px',
                  fontSize: 12,
                  width: 100, // set the width of the button
                  height: 40, // set the height of the button
                  position: 'relative',
                }}
              >
                Yes
                <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
              </Button>
            )}
          {currentSessionDetail?.medicines?.length > 0 &&
            unReviewedMedicine === 1 &&
            currentSessionDetail?.medicines
              ?.filter(medicine => medicine.review === true)
              ?.some(innerMedicine => innerMedicine.isNewMedicine === true && innerMedicine.isEligibleCondition === true) && (
              <Button
                onClick={() => handleMedicineReview()}
                autoFocus
                variant="contained"
                sx={{
                  backgroundColor: '#45C12B',
                  borderRadius: '2px', // make the button a perfect circle
                  padding: '10px 20px',
                  fontSize: 12,
                  width: 100, // set the width of the button
                  height: 40, // set the height of the button
                  position: 'relative',
                }}
              >
                Yes
                <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
              </Button>
            )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ color: '#236EBA' }}>{'How to write intervention note guide.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please add detailed and relevant intervention notes for your consultation with this patient. Remember to include all clinical
            relevant details including the healthy living advice you have provided.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            ok
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCallback} onClose={handleCloseCallBack}>
        <DialogTitle>Call Back Request</DialogTitle>
        <DialogContent>
          <TextField
            label="Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={callBackValues.date}
            onChange={e => setValuesCallBack('date', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.date}
            helperText={errorsCallBack.date}
          />
          <TextField
            label="Time"
            type="time"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={callBackValues.time}
            onChange={e => setValuesCallBack('time', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.time}
            helperText={errorsCallBack.time}
          />
          <TextField
            label="Comment"
            multiline
            rows={3}
            fullWidth
            value={callBackValues.comment}
            onChange={e => setValuesCallBack('comment', e.target.value)}
            margin="dense"
            error={!!errorsCallBack.comment}
            helperText={errorsCallBack.comment}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCallBack}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmitCallBack(sessionEntity?.id)}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            Submit
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openGp} onClose={handleCloseGp} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ color: '#236EBA' }}>
          Referral To GP
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to do GP referral.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseGp}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
            No
          </Button>
          <Button
            onClick={() => handleGpSubmit()}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            Yes
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      {openWarningLost && (
        <Box
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a semi-transparent background for overlay effect
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              padding: 2,
              backgroundColor: '#fff',
              boxShadow: 3,
              borderRadius: 2,
              width: 300, // Set a specific width for the warning box
              textAlign: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
              {/* Red warning icon */}
              <WarningIcon sx={{ color: '#F44336', marginRight: 1 }} />
              <Typography variant="h6" sx={{ color: '#F44336', fontWeight: 'bold' }}>
                Progress Lost!
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ color: '#7E7E7E', marginBottom: 2 }}>
              Please refrain from closing tab/window while reviewing medicine.
            </Typography>

            {/* Okay Button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate('/')} // Close the warning dialog when clicked
              sx={{
                padding: '8px 0',
                background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
              }}
            >
              Navigate to listing.
            </Button>
          </Box>
        </Box>
      )}

      <Backdrop open={sessionEntityLoading === true}>
        <div className="spinner"></div>
      </Backdrop>
    </Grid>
  );
};
export default CXFormCompAdmin;
