import { Autocomplete, Box, FormControl, Grid, IconButton, Menu, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './session-deo-file-uploaded.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getServiceEntities } from 'app/entities/service/service.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/pharmacy/pharmacy.reducer';
import { toast, ToastContainer } from 'react-toastify';
import { clearEntity, uploadEntity } from 'app/entities/session/session.reducer';
import LinearProgress from '@mui/material/LinearProgress';
import * as XLSX from 'xlsx';
import { permanentDeleteUnSubmittedFiles } from 'app/modules/deo-work-flow/deo-reducer';

export const FileUploadComp = () => {
  interface Pharmacy {
    id: number;
    name: string;
    pharmacyGroup: any;
  }

  const file_id = localStorage.getItem('fileId');
  const [selectPharmacy, setSelectPharmacy] = useState(0);
  const [selectService, setSelectService] = useState(0);
  const serviceEntities = useAppSelector(state => state.service.serviceEntities);
  const pharmacyEntities: Pharmacy[] = useAppSelector(state => state.pharmacy.entities);
  const currentUser = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [fileName, setFilesName] = useState('');
  const [filesData, setFilesData] = useState([]);
  const [fieldError, setFieldError] = useState({
    pharmacy_id: false,
    service_id: false,
  });
  const [progress, setProgress] = React.useState(20);
  const [fileObject, setFileObject] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const loading = useAppSelector(state => state.session.loading);
  const successMessage = useAppSelector(state => state.session.successMessage);
  const requestStatus = useAppSelector(state => state.session.requestStatus);
  const payloadStatus = useAppSelector(state => state.session.payloadStatus);
  const isDEO = useAppSelector(state => state.authentication.isDEO);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [selectPharmacyGroup, setSelectPharmacyGroup] = useState<number | null>(null); // Store selected pharmacy group id
  const setFormValues = e => {
    if (e.target.value > 0 && e.target.name === 'pharmacy_id') {
      setSelectPharmacy(e.target.value);
      localStorage.setItem('pharmacySelected', btoa(e.target.value));
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    } else if (e.target.name === 'pharmacy_id') {
      localStorage.setItem('pharmacySelected', '');
      setSelectPharmacy(e.target.value);
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    }
    if (e.target.value > 0 && e.target.name === 'service_id') {
      localStorage.setItem('serviceSelected', btoa(e.target.value));
      setSelectService(e.target.value);
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    } else if (e.target.name === 'service_id') {
      localStorage.setItem('serviceSelected', '');
      setSelectService(e.target.value);
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    }
  };

  useEffect(() => {
    dispatch(getServiceEntities({}));
    dispatch(getEntities({}));
    localStorage.setItem('pharmacyName', '');
    localStorage.setItem('pharmacyNumber', '');
    localStorage.setItem('uploadedFileName', '');
    localStorage.setItem('uploadedFileCount', '');
    localStorage.setItem('uploadedFileDeleteCount', '');
    localStorage.setItem('pharmacySelected', '');
    localStorage.setItem('serviceSelected', '');

    if (file_id !== '' && file_id !== null) {
      deleteNotSubmittedUploadedFile(file_id)
        .then(() => {
          localStorage.setItem('fileId', '');
        })
        .catch(error => {
          console.error('Error deleting or fetching uploaded files:', error);
        });
    }

    localStorage.setItem('fileName', '');
  }, []);

  const pharmacyGroups = [...new Map(pharmacyEntities.map(item => [item.pharmacyGroup.id, item.pharmacyGroup])).values()];

  // Filter pharmacies based on selected pharmacy group
  const filteredPharmacies = selectPharmacyGroup
    ? pharmacyEntities.filter(pharmacy => pharmacy.pharmacyGroup.id === selectPharmacyGroup)
    : pharmacyEntities;

  const deleteNotSubmittedUploadedFile = async (fID: any): Promise<void> => {
    await dispatch(permanentDeleteUnSubmittedFiles(fID));
    // Optionally, return something if needed
  };
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessage('Excel file or CSV file only.');
    } else if (acceptedFiles.length > 0) {
      setErrorMessage('');
      const latestFile = acceptedFiles[acceptedFiles.length - 1];
      setFileObject({
        file: latestFile,
        preview: URL.createObjectURL(latestFile),
      });
      setSelectedFileName(latestFile.name);
    }
  };

  useEffect(() => {
    if (payloadStatus !== undefined && payloadStatus !== null && payloadStatus === 201) {
      let timer;
      if (requestStatus === 'pending') {
        timer = setInterval(() => {
          setProgress(oldProgress => {
            if (oldProgress === 20) {
              clearInterval(timer);
              return 20;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 50);
          });
        }, 500);
      }

      if (requestStatus === 'fulfilled') {
        timer = setInterval(() => {
          setProgress(oldProgress => {
            if (oldProgress === 100) {
              clearInterval(timer);
              return 100;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 500);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [requestStatus]);
  useEffect(() => {
    if (payloadStatus === undefined && requestStatus === 'pending' && errorMessage === '') {
      navigate('/operator/dashboard/session-deo-file-uploaded');
    }
    if (requestStatus === 'fulfilled' && payloadStatus === 201 && !loading && progress === 100) {
      navigate('/operator/session-listing');
      toast.success(successMessage);
      dispatch(clearEntity());
    }
  }, [requestStatus && progress]);

  const handleFilesData = event => {
    const fileInput = event.target;
    const pharmacy = localStorage.getItem('pharmacySelected');
    const service = localStorage.getItem('serviceSelected');
    if (event.target.files[0] && pharmacy && service) {
      setFilesName(event.target.files[0].name);
      localStorage.setItem('uploadedFileName', event.target.files[0].name);
      dispatch(uploadEntity({ file: event.target.files[0] }))
        .then(result => {})
        .catch(error => {});
    } else {
      if (pharmacy) setFieldError(oldValues => ({ ...oldValues, ['service_id']: true }));
      else setFieldError(oldValues => ({ ...oldValues, ['pharmacy_id']: true }));

      toast.error(`Please select ${pharmacy ? 'service' : 'pharmacy'} field.`);
      fileInput.value = '';
    }

    const reader = new FileReader();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    reader.onload = event => {
      const arrayBuffer = event.target.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const records = XLSX.utils.sheet_to_json(worksheet);
      localStorage.setItem('uploadedFileCount', `${records.length}`);
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  const handleDrop = event => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile &&
      localStorage.getItem('pharmacySelected') !== '' &&
      localStorage.getItem('pharmacySelected') !== null &&
      localStorage.getItem('serviceSelected') !== '' &&
      localStorage.getItem('serviceSelected') !== null
    ) {
      setFilesName(droppedFile.name);
      localStorage.setItem('uploadedFileName', droppedFile.name);
      dispatch(uploadEntity({ file: droppedFile }))
        .then(result => {})
        .catch(error => {});
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['pharmacy_id']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['service_id']: true }));
      toast.error('Please select all fields.');
    }
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    reader.onload = event => {
      const arrayBuffer = event.target.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const records = XLSX.utils.sheet_to_json(worksheet);
      localStorage.setItem('uploadedFileCount', `${records.length}`);
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleRedirect = () => {
    const pharmacy = localStorage.getItem('pharmacySelected');
    const service = localStorage.getItem('serviceSelected');
    if (pharmacy && service) {
      navigate('/operator/create-patient');
    } else {
      if (pharmacy) setFieldError(oldValues => ({ ...oldValues, ['service_id']: true }));
      else setFieldError(oldValues => ({ ...oldValues, ['pharmacy_id']: true }));

      toast.error(`Please select ${pharmacy ? 'service' : 'pharmacy'} field.`);
    }
  };
  return (
    <Grid lg={12} container style={{ marginTop: '3%', justifyContent: 'center' }}>
      {!loading && payloadStatus !== 201 && progress === 20 ? (
        <>
          <Grid lg={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h1 className="greetingName">Hi, {currentUser && currentUser['firstName']}</h1>
              <h1 className="greeting">Have a very good day!</h1>
            </div>
            <div>
              {' '}
              <FormControl style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Autocomplete
                  id="demo-simple-select"
                  options={pharmacyGroups}
                  getOptionLabel={option => option.name}
                  value={pharmacyGroups.find(group => group.id === selectPharmacyGroup) || null}
                  onChange={(event, newValue) => setSelectPharmacyGroup(newValue ? newValue.id : null)}
                  renderInput={params => <TextField {...params} label="Select Pharmacy Group" margin="normal" />}
                  style={{
                    // marginLeft:'20%',
                    height: '30px',
                    width: '250px',
                    fontSize: '12px',
                  }}
                />
                <Autocomplete
                  id="demo-simple-select-pharmacy"
                  options={filteredPharmacies}
                  getOptionLabel={option => option.name}
                  value={pharmacyEntities.find(pharmacy => pharmacy.id === selectPharmacy) || null}
                  onChange={(event, newValue) => {
                    setFormValues({
                      target: { name: 'pharmacy_id', value: newValue ? newValue.id : 0 },
                    });
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Pharmacy"
                      margin="normal"
                      error={!!fieldError['pharmacy_id']} // Show error if 'pharmacy_id' has an error
                      helperText={fieldError['pharmacy_id'] ? fieldError['pharmacy_id'] : ''} // Display error message
                      style={{
                        height: '30px',
                        width: '250px',
                        fontSize: '12px',
                      }}
                    />
                  )}
                  style={{
                    height: '30px',
                    width: '250px',
                    fontSize: '12px',
                  }}
                />

                <Autocomplete
                  id="demo-simple-select-service"
                  options={serviceEntities}
                  getOptionLabel={option => option.name}
                  value={serviceEntities.find(service => service.id === selectService) || null}
                  onChange={(event, newValue) => {
                    setFormValues({
                      target: { name: 'service_id', value: newValue ? newValue.id : 0 },
                    });
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Service"
                      margin="normal"
                      error={!!fieldError['service_id']} // Show error if 'service_id' has an error
                      helperText={fieldError['service_id'] ? fieldError['service_id'] : ''} // Display error message
                      style={{
                        height: '30px',
                        width: '250px',
                        fontSize: '12px',
                      }}
                    />
                  )}
                  style={{
                    height: '30px',
                    width: '250px',
                    fontSize: '12px',
                  }}
                />
              </FormControl>
            </div>
          </Grid>

          <div className="Upload_button_main_div">
            <div className="Upload_button" onDrop={handleDrop} onDragOver={handleDragOver}>
              <label htmlFor="fileInput" className="fileInputLabel">
                <img
                  src={'../../../../content/assets/uploadicon.png'}
                  alt="logo"
                  style={{ height: '55px', marginLeft: 140, marginTop: 40, alignSelf: 'center' }}
                />
                <h1 className="upload_text">
                  Drag & Drop or <span className="chooseFileLink">Choose file</span> to upload
                </h1>
                <h1 className="upload_text_three">{fileName !== '' ? fileName : ''}</h1>
                {errorMessage ? (
                  <h1 className="upload_text_two_show">Please upload an excel file</h1>
                ) : (
                  <h1 className="upload_text_two">Please upload an excel file</h1>
                )}
              </label>
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFilesData} />
            </div>

            <div className="Upload_button_two">
              <div className="Upload_button_two_itemDiv">
                <div className="Upload_button_two_item">
                  <img
                    src={'../../../../content/assets/reporticon.png'}
                    alt="logo"
                    style={{ height: '55px', marginLeft: 30, marginTop: 10 }}
                  />
                </div>

                <div className="Upload_button_two_item2">
                  <h1 className="upload_Enter_Data_Manually">Enter Data Manually</h1>
                  <h1 className="upload_paragraph">
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its
                    layout.
                  </h1>
                </div>
                <div></div>
              </div>

              <IconButton
                style={{
                  backgroundColor: '#325C92',
                  marginLeft: 30,
                  marginTop: 10,
                  height: 30,
                  width: 290,
                  border: '1px solid lightgray',
                  padding: '10px',
                  borderRadius: 3,
                  justifyContent: 'space-around',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onClick={handleRedirect}
              >
                <div className="iconContainer">
                  <h1 className="iconText">Use Blank Sheet</h1>
                </div>
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <div className="Upload_file_main_div">
          <div className="Upload_file_div">
            <div className="Upload_file_div_two">
              <img src={'../../../../content/assets/exelicon.png'} alt="logo" style={{ height: '35px', marginLeft: 15, marginTop: 15 }} />
              <div className="progres_div">
                {fileName ? <h1 className="progres_file_name">{fileName}</h1> : <h1 className="progres_file_name">Upload File</h1>}
                <Box sx={{ width: '100%', marginBottom: 1 }}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <h1 className="progres_file_name">Uploading File... </h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </Grid>
  );
};

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  cursor: 'pointer',
};

const dropzoneTextStyle = {
  color: 'blue',
  fontSize: '18px',
  fontWeight: 'bold',
};

const previewContainerStyle = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const previewImageStyle = {
  maxWidth: '100px',
  maxHeight: '100px',
};

export default FileUploadComp;
