import React, { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ISessionParam } from './interface';
import RichDataTable from './rich-data-table/RichDataTable';
import useListingTable from './hooks/useListingTable';
import './listing-table.scss';
import { useAppSelector } from 'app/config/store';
import { Form, Formik } from 'formik';
import { generalMonthArray, sessionStatusesOverview } from 'app/shared/util/utits';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
import { DoDisturb, Done } from '@mui/icons-material';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
}

export const AdminInterventionsTable = props => {
  const { states, handlers } = useListingTable();
  const loadingDownload = useAppSelector(state => state.admin.loadingAdminExcelDownload);
  const { showMenu } = useDashboardContext();

  const {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allServicesPharmacist,
    allSessionListing,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    allGroupNames,
    filteredPharmacies,
    selectedMonth,
    userId,
    open,
    openReinstate,
    openEditModal,
    initialStateFollow,
    selectedPharmacies,
  } = states;
  const {
    getDownloadData,
    handleExcelPharmacyNMSRecords,
    handleRefresh,
    handleSelectService,
    handleSelectPharmacy,
    handleSelectStatus,
    handleSelectMonth,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handledateFrom,
    handledateTill,
    setUserIdAndOpenDialogue,
    setUserIdAndOpenReinstateDialogue,
    handleEdit,
    handleDelete,
    handleClose,
    handleCloseReinstate,
    handleCloseEditModal,
    onSubmit,
    handleConsent,
    reinstate,
  } = handlers;

  const formatNumber = number => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      container
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: '2%',
        transition: 'ease 0.5s',
        maxWidth: 'fit-content',
        position: 'fixed',
        marginLeft: showMenu ? '6%' : '17%',
      }}
    >
      <Grid container style={{ marginBottom: '2%' }} spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.group === '' ? 'All Groups' : Number(searchCreteria.group)}
              onChange={handleSelectGroup}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Groups'}>All Groups</MenuItem>
              {allGroupNames.map((elem, index) => {
                return (
                  <MenuItem key={elem.id} value={elem.id}>
                    {elem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              multiple
              value={selectedPharmacies}
              onChange={handleSelectPharmacy}
              displayEmpty
              renderValue={selected => {
                if (selected.length === 0) {
                  return 'All Pharmacies';
                }
                const selectedPharmacyNames = selected.map(id => {
                  const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                  return pharmacy ? pharmacy.name : '';
                });
                return (
                  <div>
                    {selectedPharmacyNames.map(name => (
                      <Chip key={name} label={name} size="small" />
                    ))}
                  </div>
                );
              }}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
              {filteredPharmacies.map(elem => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.servicePharmacist === '' ? 'All Service Pharmacist' : searchCreteria.servicePharmacist}
              onChange={handleSelectService}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Service Pharmacist'}>All Service Pharmacist</MenuItem>
              {allServicesPharmacist.map((elem, index) => {
                return (
                  <MenuItem key={elem.id} value={elem.id}>
                    {elem.firstName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.status === '' ? 'Status' : searchCreteria.status}
              onChange={handleSelectStatus}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'Status'}>Status</MenuItem>
              {sessionStatusesOverview.map(elem => {
                return (
                  <MenuItem key={elem.id} value={elem.name}>
                    {elem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2} spacing={2}>
            <Grid item xs={2}>
              <Tooltip title="Total NMS by pharmacy">
                <IconButton
                  onClick={() => handleExcelPharmacyNMSRecords()}
                  style={{
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '30px' }} />
                  <Typography variant="h6" style={{ marginLeft: '2px' }}>
                    Total:
                  </Typography>
                  <Typography variant="h6" style={{ marginLeft: '2px' }}>
                    {formatNumber(totalCount)}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', gap: 10, marginLeft: -25 }}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <Tooltip title="Download">
                <IconButton
                  onClick={() => getDownloadData()}
                  style={{
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '30px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              disabled={
                searchCreteria.status !== 'Completed NMS' &&
                searchCreteria.status !== 'Completed Interventions' &&
                searchCreteria.status !== 'Completed Follow-ups'
              }
              value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
              onChange={handleSelectMonth}
              className={'mui-field-design-filter'}
            >
              {generalMonthArray.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    // className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateFrom(newValue)}
                    className={'mui-field-design-filter'}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>

          <Grid item xs={2}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock mui-field-design-filter"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateTill(newValue)}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>
        <Paper style={{ width: '100%', border: 'none' }}>
          <RichDataTable
            limit={limit ? limit : 50}
            page={pageNo ? pageNo : 0}
            count={totalCount ? totalCount : 0}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['index', 'patient.firstName', 'patient.telephone', 'patient.address', 'pharmacy.name', 'sessionStatus']}
            dataSource={allSessionListing}
            headers={['ID', 'Patient Name', 'Primary Contact', 'Address', 'Pharmacy', 'Status']}
            showEditDeletButton={true}
            handleDelete={setUserIdAndOpenDialogue}
            handleEdit={handleEdit}
            showConsentIcon={true}
            handleConsent={handleConsent}
            reinstate={setUserIdAndOpenReinstateDialogue}
            showStartNMSIcon={true}
            showReinstateNMSIcon={true}
          />
        </Paper>
      </Grid>
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik enableReinitialize initialValues={initialStateFollow} onSubmit={onSubmit}>
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    width: 550,
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '12%',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#325C92',
                      marginTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    Update Follow-up Date
                  </h1>
                  <h1
                    style={{
                      fontSize: '0.8rem',
                      color: 'black',
                      marginTop: '12px',
                      textAlign: 'center',
                    }}
                  ></h1>
                  <div
                    style={{
                      marginTop: '6%',
                      width: '75%',
                      height: '12%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        className="clock"
                        format="DD/MM/YYYY"
                        value={values.followUpdDate}
                        onChange={newValue => setFieldValue('followUpdDate', newValue)}
                        sx={{ marginRight: '10px', border: '', width: '100%', height: '80%' }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8%',
                      marginBottom: '5%',
                      backgroundColor: '',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconButton
                      style={{
                        backgroundColor: '#EFEFEF',
                        height: '100%',
                        width: '28%',
                        border: '1px solid lightgray',
                        padding: '10px',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      onClick={handleCloseEditModal}
                      // disabled={disabled}
                    >
                      <img
                        src={'../../../../content/assets/cancel2.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'black',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Cancel
                      </h1>
                    </IconButton>

                    <IconButton
                      style={{
                        backgroundColor: '#21AD64',
                        marginLeft: '2%',
                        height: '100%',
                        width: '28%',
                        border: '1px solid lightgray',
                        padding: '10px',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      type="submit"
                      // onClick={handleSessionSubmit}
                    >
                      <img
                        src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Update
                      </h1>
                    </IconButton>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            {' '}
            No
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
          </Button>
          <Button
            onClick={() => handleDelete(userId)}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            Yes
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReinstate}
        onClose={handleCloseReinstate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reinstate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to reinstate.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseReinstate}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#E90016',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            {' '}
            No
            <DoDisturb sx={{ position: 'absolute', left: 5, paddingLeft: '2px', fontSize: '15px' }} />
          </Button>
          <Button
            onClick={() => reinstate(userId)}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: '#45C12B',
              borderRadius: '2px', // make the button a perfect circle
              padding: '10px 20px',
              fontSize: 12,
              width: 100, // set the width of the button
              height: 40, // set the height of the button
              position: 'relative',
            }}
          >
            Yes
            <Done sx={{ position: 'absolute', right: 5, fontSize: '15px' }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default AdminInterventionsTable;
