import React, { createContext, useContext, useState } from 'react';

export const SelectRoleContext = createContext(null);

export const SelectRoleProvider = ({ children }) => {
  const [selectRole, setSelectRole] = useState(null);

  const handleRoleSelect = role => {
    setSelectRole(role);
  };

  return <SelectRoleContext.Provider value={{ selectRole, handleRoleSelect }}>{children}</SelectRoleContext.Provider>;
};

export const useSelectRoleContext = () => {
  const context = useContext(SelectRoleContext);
  if (!context) {
    throw new Error('useSelectRoleContext must be used within a SelectRoleProvider');
  }
  return context;
};
